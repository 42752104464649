import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Grid, Avatar, Box } from "@mui/material";
import { padding, styled, textAlign } from "@mui/system";
import VerifiedIcon from "../../Images/verified 1.png";
import StarIcon from "@mui/icons-material/Star";
import AskImage from '../../Images/askimage.png';
import { Link } from "react-router-dom";
import './HomeCard.css'

// Theme-based styling for StyledCard
const StyledCard = styled(Card)(({ isSelected, themeMode }) => ({
  background: themeMode === "dark"
    ? "linear-gradient(45deg, #1C1C1C, #333333)" // Dark mode gradient
    : "linear-gradient(45deg, #d9d9d921, #FCCEE6)", // Light mode gradient
  color: themeMode === "dark" ? "#FFFFFF" : "#000000", // Ensure text is readable
  borderRadius: "16px",
  boxShadow: "none",
  padding: "12px",
  border: isSelected ? "3px solid #EE0C81" : "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  maxWidth: "auto",
  margin: "0px",
  overflow: "visible",
  cursor: "pointer",
}));

const StyledAvatar = styled(Avatar)({
  width: "130px",
  height: "130px",
  borderRadius: "5%",
  border: "3px solid #800080",
});

const AskButton = styled(Box)({
  color: "white",
  padding: "5px 10px",
  borderRadius: "20px",
  fontSize: "12px",
  fontWeight: "bold",
  cursor: "pointer",
  textAlign: "center",
  position: "relative",
  right: "-61px",
});

const UserCard = ({ user, onCardClick, isSelected, themeMode ,viewMode }) => {
  const cardStyles = {
    ...(viewMode === 'grid' ? {
      
      flexDirection: 'column',
      height: '100%',
      alignItems: 'center',
      textAlign: 'center',
    } : {})
  };
  if (viewMode === 'list') {
    // List View Card
    return (
      <StyledCard
        className={`${themeMode === "dark" ? "black-color" : "light-theme"} list-card`}
        isSelected={isSelected}
        themeMode={themeMode}
        onClick={() => onCardClick(user)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '12px',
          borderRadius: '12px',
          background: 'linear-gradient(to right, rgba(217, 217, 217, 0.1), #FCCEE6)',
          position: 'relative',
          mb: 2
        }}
      >
        {/* Profile Image */}
        <Box position="relative" mr={2} className={isSelected ? "mark-check" : "withou-mark-check"} >
          <Avatar 
            src={user.profileImage} 
            sx={{ 
              width: 80, 
              height: 80,
              border: '2px solid #800080',
              borderRadius: '12px'
            }} 
           
          />
          {user.active && (
            <Box
              sx={{
                position: "absolute",
                top: 5,
                right: 5,
                width: 12,
                height: 12,
                backgroundColor: "#3E9C35",
                borderRadius: "50%",
                border: "2px solid white",
              }}
            />
          )}
        </Box>

        {/* User Info */}
        <Box flexGrow={1}>
          <Box display="flex" alignItems="center" mb={0.5}>
            <Typography variant="h6" className="user">
              {user.name}
            </Typography>
            {user.verified && (
              <img
                src={VerifiedIcon}
                alt=""
                className="ms-1"
                style={{ height: "20px", width: "20px" }}
              />
            )}
          </Box>
          
          <Box mb={1} className="mt-2">
            <Typography variant="body2">
              {user.hashtags && user.hashtags.map((tag, index) => (
                <span
                  key={index}
                  style={{
                    color: index % 2 === 0 ? "#000" : "#FF1493",
                    marginRight: '8px'
                  }}
                >
                  #{tag.title}
                </span>
              ))}
            </Typography>
          </Box>
          
          <Box 
            display="flex" 
            alignItems="center"
            sx={{
              '& > div': {
                pr: 3,
                borderRight: '1px solid #e0e0e0',
                '&:last-child': {
                  borderRight: 'none',
                  pr: 0
                }
              }
            }}
            style={{textAlign:"initial"}}
          >
            <Box pr={3} className="user-titels">
              <Typography variant="body2" color="text.secondary">Price</Typography>
              <Typography variant="body1" className="user-price" fontWeight="bold">${user.hoursPrice}</Typography>
            </Box>
            
            <Box px={3} className="user-titels">
              <Typography variant="body2" color="text.secondary">Rating</Typography>
              <Box>
                {[...Array(5)].map((_, i) => (
                  <StarIcon 
                    key={i} 
                    fontSize="small" 
                    style={{ 
                      color: i < user.rating ? "#ffcc00" : "#e0e0e0",
                      width: '16px',
                      height: '16px'
                    }} 
                  />
                ))}
              </Box>
            </Box>
            
            <Box pl={3} className="user-titels">
              <Typography variant="body2" color="text.secondary">Chats</Typography>
              <Typography variant="body1" className="user-price" fontWeight="bold">{user.chatCount}</Typography>
            </Box>
          </Box>
        </Box>
        
        {/* View Detail Link */}
        <Box position="absolute" top={12} right={12}>
          <Link 
            to=""
            style={{
              color: '#B40FE7',
              textDecoration: 'underline',
              fontWeight: '400',
              fontSize: '14px'
            }}
          >
            View Detail
          </Link>
        </Box>
      </StyledCard>
    );
  } else {
    // Grid View Card (keeping existing grid view implementation)
    return (
      <StyledCard
        className={`${themeMode === "dark" ? "black-color" : "light-theme"} grid-card`}
        display="flex"
        flexDirection="column"
        isSelected={isSelected}
        themeMode={themeMode}
        onClick={() => onCardClick(user)}
      >
        {/* Grid view avatar centered at top */}
        <Box display="flex" justifyContent="center" width="100%" mb={1}  >
          <Box position="relative" display="inline-block" id='grid-view-active' className={isSelected ? "mark-check-for-grid" : "withou-mark-check"} >
            <StyledAvatar src={user.profileImage} />
            {user.active && (
              <Box
                display="flex"
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  width: 12,
                  height: 12,
                  backgroundColor: "#00ff00",
                  borderRadius: "50%",
                  border: "2px solid white",
                }}
              />
            )}
          </Box>
        </Box>

        <CardContent sx={{ width: '100%', padding: '8px 16px' }}>
          <Typography variant="h6" fontWeight="bold" textAlign="left">
            {user.name}{" "}
            {user.verified && (
              <img
                src={VerifiedIcon}
                alt=""
                className="mt-1 ms-1"
                style={{ height: "20px", width: "20px" }}
              />
            )}
          </Typography>
          
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="body2" textAlign="left" className="user-rating">
              Price: <span className="gradient-text">${user.hoursPrice}</span>
            </Typography>
            
            <Typography variant="body2" textAlign="left" className="user-rating">
              Rating: {" "}
              {[...Array(user.rating)].map((_, i) => (
                <StarIcon key={i} fontSize="small" style={{ color: "#ffcc00" }} />
              ))}
            </Typography>
            
            <Typography variant="body2" textAlign="left" className="user-rating">
              Chats: <span className="gradient-text">{user.chatCount}</span>
            </Typography>
          </Box>
          
          <Box mt={1}>
            <Typography variant="body2" textAlign="left">
              {user.hashtags && user.hashtags.map((tag, index) => (
                <span
                  key={index}
                  style={{
                    color: index % 2 === 0 ? "#d63384" : "black",
                    background: "linear-gradient(90deg, #850D9B, #F10B80)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  #{tag.title}{" "}
                </span>
              ))}
            </Typography>
          </Box>
        </CardContent>
        
        {/* Center the View Detail button in grid view */}
        <Box 
          width="100%" 
          display="flex" 
          justifyContent="center" 
          mt="auto"
          mb={2}
        >
            <Link 
            to=""
            style={{
              color: '#B40FE7',
              textDecoration: 'underline',
              fontWeight: '400',
              fontSize: '14px'
            }}
          >
            View Detail
          </Link>
        </Box>
      </StyledCard>
    );
  }
};

const CardList = ({ users,selectedHashtags = [] ,handleSelect,handleUpdateUserCount,highlightUsers,setShowhastagArraySearch,viewMode}) => {
  console.log('We are passed users in the cardlist', users);
  console.log('We are selected hastags in the cardlist',selectedHashtags);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [filteredUsers, setFilteredUsers] = useState(users);

  
  useEffect(()=>{
    if(highlightUsers.length===0){
      setSelectedUsers([]);
      setShowhastagArraySearch(true)
    }
  },[highlightUsers])

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(()=>{
    if (!selectedHashtags.length) {
      setFilteredUsers(users);
      return;
    }

    // Filter users based on selected hashtags
    const filtered = users.filter(user => {
      // If user has no hashtags, exclude them when filtering
      if (!user.hashtags || user.hashtags.length === 0) return false;
      
      // Check if any of the user's hashtags match the selected hashtags
      return user.hashtags.some(tag => 
        selectedHashtags.includes(tag.title)
      );
    });
    
    setFilteredUsers(filtered);

  },[users,selectedHashtags])

  // const users = [
  //   {
  //     name: "Alice Smith",
  //     image: "https://via.placeholder.com/60",
  //     price: 5,
  //     rating: 4,
  //     chats: 120,
  //     hashtags: ["fitness", "lifestyle", "coach"],
  //     verified: true,
  //     active: true,
  //   },
  //   {
  //     name: "John Doe",
  //     image: "https://via.placeholder.com/60",
  //     price: 10,
  //     rating: 5,
  //     chats: 350,
  //     hashtags: ["developer", "mentor", "tech"],
  //     verified: true,
  //     active: false,
  //   },
  //   {
  //     name: "Emily Rose",
  //     image: "https://via.placeholder.com/60",
  //     price: 8,
  //     rating: 3,
  //     chats: 200,
  //     hashtags: ["design", "art", "creative"],
  //     verified: false,
  //     active: true,
  //   },
  // ];

  const handleCardClick = (user) => {
    setSelectedUsers(prevSelectedUsers => {
      // Check if user is already selected
      const isSelected = prevSelectedUsers.some(selectedUser => selectedUser._id === user._id);
      
      // If selected, remove from array, otherwise add to array
      const newSelectedUsers = isSelected
        ? prevSelectedUsers.filter(selectedUser => selectedUser._id !== user._id)
        : [...prevSelectedUsers, user];
      
      // If parent component needs to know about selection changes
      if (handleUpdateUserCount) {
        handleUpdateUserCount(newSelectedUsers);
      }

      return newSelectedUsers;
    });
  };

  return (
    <Grid 
    style={{paddingTop:"0px"}}
    container 
    spacing={{ xs: 2, md: viewMode === 'grid' ? 3 : 6 }}
    className={`mt-0 ${viewMode === 'grid' ? 'grid-view-container' : 'list-view-container'}`}
  >
    {filteredUsers?.map((user, index) => (
      <Grid 
      style={{paddingTop:"10px"}}
      className="mt-2"
        item 
        xs={12} 
        sm={viewMode === 'grid' ? 6 : 12} 
        md={viewMode === 'grid' ? 4 : 12} 
        key={index}
      >
        <UserCard
          user={user}
          onCardClick={handleCardClick}
          isSelected={selectedUsers.some(selectedUser => selectedUser._id === user._id)}
          themeMode={theme}
          viewMode={viewMode} // Pass viewMode to UserCard
         
        />
      </Grid>
    ))}
  </Grid>
  );
};

export default CardList;
