import React, { useState } from "react";
import { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "../../Images/Avatar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import Overview from "./Overview";
import Wallet from "./Wallet/index";
import DraftList from "./DraftList/index";
import ContactUs from "./ContactUs/index";
import AllContracts from "./AllContracts/index";
import AllDisputes from "./AllDisputes/index";
import TransectionHistory from "./TransectionHistory/index";
import MyEarning from "./MyEarning/index";
import AppliedRequest from "./AppliedRequests/AppliedRequest";
import Logout from "./Logout/index";
import Identificationn from "./Identification/index";
import DeleteAccount from "./DeleteAccount/index";
import HourlyPrice from "./HourlyPrice/index";
import Help from "./Help/index";
import Hashtags from "./Hashtags/index";
import IDVerification from "./IDVerification/index";
import PhoneNumber from "./PhoneNumber";
import YourImage from "../../Images/user.png";
import profile2 from "../../Images/profile-2.png";
import profile3 from "../../Images/profile-3.png";
import profile4 from "../../Images/profile-4.png";
import profile5 from "../../Images/profile-5.png";
import profile6 from "../../Images/profile-6.png";
import profile7 from "../../Images/profile-7.png";
import profile8 from "../../Images/profile-8.png";
import profile9 from "../../Images/profile-9.png";
import IdVerification from "../../Images/IdVerification.png";
import Identification from "../../Images/Identification.png";
import HourlyPrice2 from "../../Images/HourlyPrice.png";
import Hashtags2 from "../../Images/Hashtags.png";
import Wallet2 from "../../Images/Wallet.png";
import Addbank from "../../Images/Add bank.png";
import Transactionhistory from "../../Images/Transaction history.png";
import Appliedrequests2 from "../../Images/Applied requests.png";
import Allcontracts2 from "../../Images/All contracts.png";
import Alldisputes2 from "../../Images/All disputes.png";
import Phonenumber2 from "../../Images/Phone number.png";
import Contactus2 from "../../Images/Contact us.png";
import Deleteaccount2 from "../../Images/Deleteaccount.png";
import Help2 from "../../Images/Help.png";
import Earning from "../../Images/Help.png";
import { AccountCircle } from "@mui/icons-material"; // Importing AccountCircle icon from Material-UI icons
import { getMyProfile } from "../../services/api/profileApi";
// import Loaders from "../Loaders/index";
import CompleteProfileUser from "../../Images/CompleteProfileUser.svg";




function CustomImage() {
  return (
    <img src={YourImage} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomImage2() {
  return (
    <img src={profile2} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomImage3() {
  return (
    <img src={profile3} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomImage4() {
  return (
    <img src={profile4} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomImage5() {
  return (
    <img src={profile5} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomImage6() {
  return (
    <img src={profile6} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomImage7() {
  return (
    <img src={profile7} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomImage8() {
  return (
    <img src={Earning} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomImage9() {
  return (
    <img src={profile9} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomIdVerification() {
  return (
    <img
      src={IdVerification}
      alt="Your Alt Text"
      className="me-2 image-inline"
    />
  );
}
function CustomIdentification() {
  return (
    <img
      src={Identification}
      alt="Your Alt Text"
      className="me-2 image-inline"
    />
  );
}
function CustomHourlyPrice() {
  return (
    <img src={HourlyPrice2} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomHashtags() {
  return (
    <img src={Hashtags2} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomWallet2() {
  return (
    <img src={Wallet2} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomAddbank() {
  return (
    <img src={Addbank} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomTransactionhistory() {
  return (
    <img
      src={Transactionhistory}
      alt="Your Alt Text"
      className="me-2 image-inline"
    />
  );
}
function CustomAppliedrequests2() {
  return (
    <img
      src={Appliedrequests2}
      alt="Your Alt Text"
      className="me-2 image-inline"
    />
  );
}
function CustomAlldisputes2() {
  return (
    <img
      src={Alldisputes2}
      alt="Your Alt Text"
      className="me-2 image-inline"
    />
  );
}function CustomAllcontracts2() {
  return (
    <img
      src={Allcontracts2}
      alt="Your Alt Text"
      className="me-2 image-inline"
    />
  );
}
function CustomHelp() {
  return (
    <img src={Help2} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomDeleteaccount() {
  return (
    <img src={Deleteaccount2} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomContactus() {
  return (
    <img src={Contactus2} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
function CustomPhonenumber() {
  return (
    <img src={Phonenumber2} alt="Your Alt Text" className="me-2 image-inline" />
  );
}

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const VerticalTabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  const [profile, setProfile] = useState({ name: "", email: "" });
  // const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  // const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getMyProfile();
        console.log(data.data.user, "datadatadata");
        setProfile(data.data.user);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchProfile();
  }, []);

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  // console.log("modalOpen", modalOpen)

  if (loading) {
    return <div>{/* <Loaders /> */}</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container">
      <div className="row mt-5 mb-5">
        <div className="col-lg-3 side-nav ">
          <div
            className={`inner-shadow pt-5 p-2  theme-switcher ${
              theme === "dark" ? "inner-shadow-dark" : "light-theme"
            }`}
          >
            <center>
              <img
                src={profile?.profileImage || CompleteProfileUser}
                alt=""
                className="side-bar-profile object-fit-cover"
              />
            </center>
            <div className="side-bar-name pb-3">
            <h2
  className={`theme-switcher pt-2 ${
    theme === "dark" ? "dark-theme-only-color" : "light-theme"
  }`}
>
  {profile?.name?.length > 28
    ? `${profile.name.substring(0, 28)}...`
    : profile?.name || "No Name"}
</h2>
              {/* <p
                className={`pb-2 theme-switcher ${
                  theme === "dark" ? "dark-theme-only-color" : "light-theme"
                }`}
              >
                {profile.email
  ? profile.email.length > 20
    ? `${profile.email.substring(0, 20)}...`
    : profile.email
  : "No email available"}
              </p> */}

              <div className="pb-3 side-bar-name pt-2" >
                <div
                  style={{
                    display:
                      profile &&
                      profile?.videoVerify &&
                      profile?.videoVerify === 1
                        ? "none"
                        : "block",
                  }}
                >
                  {/* <Identificationn /> */}
                </div>
              </div>
            </div>
            <div className="new-side-scroll">
            <p className={`profile-lebel pb-2 theme-switcher ${
                  theme === "dark" ? "dark-theme-only-color" : "light-theme"
                }`}>Personal and Security</p>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 0, borderColor: "divider" }}
                
                className={`pb-2 profile-border theme-switcher ${
                  theme === "dark" ? "profile-border-dark-screen" : "light-theme"
                }`}
              >
                <Tab
                  value={0}
                  label="My Account"
                  icon={<CustomImage />}
                  {...a11yProps(0)}
                  className={` tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color-new" : "light-theme"
                  }`}
                />
                <Tab
                  value={13}
                  label="Id Verification"
                  icon={<CustomHourlyPrice />}
                  {...a11yProps(10)}
                  className={` tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color-new" : "light-theme"
                  }`}
                />
 <Identificationn />
              
              </Tabs>
              <p className={`profile-lebel pb-2 pt-3 theme-switcher ${
                  theme === "dark" ? "dark-theme-only-color" : "light-theme"
                }`}>Professional details</p>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 0, borderColor: "divider" }}
                   className={`pb-2 profile-border theme-switcher ${
                  theme === "dark" ? "profile-border-dark-screen" : "light-theme"
                }`}
              >
                <Tab
                  value={10}
                  label="Hourly Price"
                  icon={<CustomHourlyPrice />}
                  {...a11yProps(10)}
                  className={` tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color-new" : "light-theme"
                  }`}
                />

                <Tab
                  value={12}
                  label="Hashtags"
                  icon={<CustomHashtags />}
                  {...a11yProps(12)}
                  className={` tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color-new" : "light-theme"
                  }`}
                />
              </Tabs>

              <p className={`profile-lebel pb-2 pt-3 theme-switcher ${
                  theme === "dark" ? "dark-theme-only-color" : "light-theme"
                }`}>Finances</p>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 0, borderColor: "divider" }}
                className={`pb-2 profile-border theme-switcher ${
                  theme === "dark" ? "profile-border-dark-screen" : "light-theme"
                }`}
              >
                <Tab
                  label="Wallet"
                  value={1}
                  icon={<CustomWallet2 />}
                  {...a11yProps(1)}
                  className={` tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color-new" : "light-theme"
                  }`}
                />

                <Tab
                  label="Add Bank"
                  value={22}
                  icon={<CustomAddbank />}
                  {...a11yProps(1)}
                  className={` tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color-new" : "light-theme"
                  }`}
                />
                <Tab
                  label="Transaction History"
                  icon={<CustomTransactionhistory />}
                  {...a11yProps(6)}
                  value={7}
                  className={` tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color-new" : "light-theme"
                  }`}
                />
                 <Tab
                  label="Earnings"
                  value={8}
                  icon={<CustomImage8 />}
                  {...a11yProps(7)}
                  className={` tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color-new" : "light-theme"
                  }`}
                />
              </Tabs>

              <p className={`profile-lebel pb-2 pt-3 theme-switcher ${
                  theme === "dark" ? "dark-theme-only-color" : "light-theme"
                }`}>Activities and Requests</p>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 0, borderColor: "divider" }}
                className={`pb-2 profile-border theme-switcher ${
                  theme === "dark" ? "profile-border-dark-screen" : "light-theme"
                }`}
              >
                 <Tab
                  label="Applied Requests"
                  value={5}
                  icon={<CustomAppliedrequests2 />}
                  {...a11yProps(10)}
                  className={`tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color-new" : "light-theme"
                  }`}
                />


              
                <Tab
                  label="All Disputes"
                  value={6}
                  icon={<CustomAlldisputes2 />}
                  {...a11yProps(5)}
                  className={` tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color-new" : "light-theme"
                  }`}
                />
                <Tab
                  label="All Contracts"
                  value={4}
                  icon={<CustomAllcontracts2 />}
                  {...a11yProps(4)}
                  className={` tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color-new" : "light-theme"
                  }`}
                />
              </Tabs>

              
              <p className={`profile-lebel pb-2 pt-3 theme-switcher ${
                  theme === "dark" ? "dark-theme-only-color" : "light-theme"
                }`}>Data and Privacy</p>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 0, borderColor: "divider" }}
                className={`pb-2 profile-border theme-switcher ${
                  theme === "dark" ? "profile-border-dark-screen" : "light-theme"
                }`}
              >
                  <Tab
                  label="Delete Account"
                  value={9}
                  icon={<CustomDeleteaccount />}
                  {...a11yProps(9)}
                  className={` tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color-new" : "light-theme"
                  }`}
                />
                <Tab
                  label="Phone number"
                  value={14}
                  icon={<CustomPhonenumber />}
                  {...a11yProps(9)}
                  className={` tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color-new" : "light-theme"
                  }`}
                />
                  <Tab
                  label="Help"
                  value={11}
                  icon={<CustomHelp />}
                  {...a11yProps(11)}
                  className={` tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color-new" : "light-theme"
                  }`}
                />
                  <Tab
                  label="Contact us"
                  value={3}
                  icon={<CustomContactus />}
                  {...a11yProps(3)}
                  className={` tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color-new" : "light-theme"
                  }`}
                />


              
                
              </Tabs>

              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 0, borderColor: "divider" }}
              >
             

                {/* <Tab
                  value={0}
                  label="My Account"
                  icon={<CustomImage />}
                  {...a11yProps(0)}
                  className={` tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color" : "light-theme"
                  }`}
                /> */}

                {/* <Tab
                  label="Draft List"
                  icon={<CustomImage5 />}
                  {...a11yProps(2)}
                  className={` tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color" : "light-theme"
                  }`}
                /> */}
              
                

              
              

             

                <div
                  // onClick={() => setModalOpen(true)}
                  className={`tabs-tab theme-switcher ${
                    theme === "dark" ? "dark-theme-only-color" : "light-theme"
                  }`}
                >
                  <Logout
                  // modalOpen={modalOpen}
                  />
                </div>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="col-lg-9 p-0">
          <TabPanel value={value} index={0} sx={{ Padding: "0px" }}>
            <Overview />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Wallet />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <DraftList />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ContactUs />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <AllContracts />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <AppliedRequest />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <AllDisputes />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <TransectionHistory />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <MyEarning />
          </TabPanel>
          <TabPanel value={value} index={9}>
            <DeleteAccount />
          </TabPanel>
          <TabPanel value={value} index={10}>
            <HourlyPrice />
          </TabPanel>
          <TabPanel value={value} index={11}>
            <Help />
          </TabPanel>
          <TabPanel value={value} index={12}>
            <Hashtags />
          </TabPanel>
          <TabPanel value={value} index={13}>
            <IDVerification />
          </TabPanel>
          <TabPanel value={value} index={14}>
            <PhoneNumber />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default VerticalTabs;
