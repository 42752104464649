import React, { useState, useEffect, useCallback } from "react";
import { Formik, Field, ErrorMessage, Form as FormikForm, useFormikContext } from "formik";
import * as Yup from "yup";
import logo from "../../Images/33512733_8058698_1__1_-removebg-preview.png";
import Form from "react-bootstrap/Form";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import InputGroup from "react-bootstrap/InputGroup";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Input from "@mui/material/Input";
import Modal from "@mui/material/Modal";
import xmark from "../../Images/x-mark.png";
import LightBulb from "../../Images/3d-render-hand-with-light-bulb-creative-idea.png";
import { getMyProfile, photoUpload, profileComplete, scanDocument, listEthnics, listLanguages, updateHashtags, updateProfile } from "../../services";
import { notifyError, notifySuccess } from "../../toastNotifications";
import NavbarSignFlow from "../Login/NavbarSignFlow";
// import CustomDatePickerWithoutMonth from './CustomDatePickerWithoutMonth';
import Image from "../../Images/Group 1948757617.png";
import AttechFile from "./AttechFile";
import { InputAdornment, TextField } from "@mui/material";

import { IconButton } from "@mui/material";
import { Upload } from "@mui/icons-material";
import UploadFile from '../../Images/Vector (1).svg';
import UploadFile2 from '../../Images/Vector (2).svg';
import UploadFile3 from '../../Images/Vector (3).svg';
import Loaders from '../Loaders/index'
import Hashtags from './HashtagComponent';
import { MultiSelect } from 'react-multi-select-component'
import { GoogleGenerativeAI } from "@google/generative-ai";
import stripJsonComments from 'strip-json-comments';











function Index() {

  const genAI = new GoogleGenerativeAI(process.env.GEMINI_API_KEY);


  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [profileImageLoading, setProfileImageLoading] = useState(false);

  const [formikData, setFormikData] = useState(null);

  const [ethnicities, setEthnicities] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedEthnic, setSelectedEthnic] = useState('');
  const [selectedLanguages, setSelectedLanguages] = useState([]);



  const [imagePreview, setImagePreview] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const [modelOpenStatus, setModelOpenStatus] = useState(false);
  const [imageError, setImageError] = useState(null);
  const [selectedHashtags, setSelectedHashtags] = useState([]);
  const [dataFromGemini, setDataFromGemini] = useState({});
  const [profileData, setProfileData] = useState({});
  const [selectedDocumentType, setselectedDocumentType] = useState('');



  console.log('I am geminidata state', dataFromGemini);


  const handleHashtagsChange = useCallback((hashtags) => {
    let sendusehastags = function convertHashtags(hashtags) {
      return hashtags?.map(hashtag => ({ title: hashtag.title }));
    }
    let hastagstosubmit = sendusehastags(hashtags);
    updateHashtags(formikData?.hoursPrice, hastagstosubmit);

    setSelectedHashtags(hastagstosubmit);
    console.log('Hashtags in parent:', hashtags);
  }, []);

  const customValueRenderer = (selected, _options) => {
    return selected.length
      ? `${selected.length} language${selected.length !== 1 ? 's' : ''} selected`
      : "Select Languages";
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'pink' : 'white',
      color: state.isSelected ? 'black' : 'inherit',
      ':hover': {
        backgroundColor: 'lightpink',
      },
    }),
  };



console.log('I am formik data', formikData)


  // document
  const [documentPreview, setDocumentPreview] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);
  const [documentDataUrl, setDocumentDataUrl] = useState(null);
  const [documentError, setDocumentError] = useState(null);


  const [scanOutputData, setScanOutputData] = useState(null);
  const [scanOutputModel, setScanOutputModel] = useState(true);
  const [scanDocumentStatusCode, setScanDocumentStatusCode] = useState(0)


  function getZodiacSign(dob) {
    const date = new Date(dob); // Convert the input to a Date object
    const month = date.getMonth() + 1; // getMonth() returns 0-based month, so add 1
    const day = date.getDate(); // getDate() returns the day of the month

    switch (true) {
      case (month === 1 && day >= 20) || (month === 2 && day <= 18):
        return "Aquarius";
      case (month === 2 && day >= 19) || (month === 3 && day <= 20):
        return "Pisces";
      case (month === 3 && day >= 21) || (month === 4 && day <= 19):
        return "Aries";
      case (month === 4 && day >= 20) || (month === 5 && day <= 20):
        return "Taurus";
      case (month === 5 && day >= 21) || (month === 6 && day <= 20):
        return "Gemini";
      case (month === 6 && day >= 21) || (month === 7 && day <= 22):
        return "Cancer";
      case (month === 7 && day >= 23) || (month === 8 && day <= 22):
        return "Leo";
      case (month === 8 && day >= 23) || (month === 9 && day <= 22):
        return "Virgo";
      case (month === 9 && day >= 23) || (month === 10 && day <= 22):
        return "Libra";
      case (month === 10 && day >= 23) || (month === 11 && day <= 21):
        return "Scorpio";
      case (month === 11 && day >= 22) || (month === 12 && day <= 21):
        return "Sagittarius";
      case (month === 12 && day >= 22) || (month === 1 && day <= 19):
        return "Capricorn";
      default:
        return "Unknown";
    }
  }

  const[username,setUsername]=useState('');

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .test(
        "notEmptyAfterTrim",
        "Name is required",
        (value) => value.trim() !== ""
      )
      .required("Name is required"),
    identity: Yup.string()
      .oneOf(["0", "1", "2"], "identity is required")
      .required("identity is required"),
    hoursPrice: Yup.number()
      .positive("positive number allowed only")
      .required("Hourly price is required")
      .max(
        10000,
        "You can set the hourly price up to 10,000 dollars only."
      ),

  });

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log(`values`, values);
    console.log(`documentFile`, documentFile);

    console.log('HandleSubmit called')



    if (!imageFile && !formikData?.profileImage) {
      notifyError(`Profile image required`);
      return;
    } else {
    }

    if (!documentFile && !formikData?.document) {
      notifyError(`Identity document image required`);
      return;
    }
    console.log('I am scandocument status code inside handleSubmit', scanDocumentStatusCode)
    // if(scanDocumentStatusCode!==200){
    //   notifyError('Your verification document does not match your document type!!');
    //   return;
    // }

    const {
      name,
      identity,
      hoursPrice,
    } = values;
    setSubmitting(true);

    if (!imageFile && !formikData?.profileImage && !documentFile && !formikData?.document) {
      setImageError(true);
      setSubmitting(false);
    } else {
      setImageError(false);
      try {
        const newForm = new FormData();
        const file = imageFile;
        if (!file && !formikData?.profileImage) {
          console.error("No file selected");
          notifyError(`Image required`);
          setSubmitting(false);
          return;
        }

        if (file) {
          const reader = new FileReader();
          const dataURL = imageDataUrl;
          const byteString = atob(dataURL.split(",")[1]);
          const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([ab], { type: mimeString });
          reader.readAsDataURL(file);
          newForm.append("profileImage", blob, file.name);
        } else {
          newForm.append("profileImage", formikData?.profileImage);
        }


        const documentFileInner = documentFile;

        if (!documentFileInner && !formikData?.document
        ) {
          console.error("No file selected");
          notifyError(`Identity document image required`);
          setSubmitting(false);
          return;
        }

        if (documentFileInner) {
          const reader = new FileReader();
          const documentDataURL = documentDataUrl;
          const documentByteString = atob(documentDataURL.split(",")[1]);
          const documentMimeString = documentDataURL.split(",")[0].split(":")[1].split(";")[0];
          const docab = new ArrayBuffer(documentByteString.length);
          const docia = new Uint8Array(docab);
          for (let i = 0; i < documentByteString.length; i++) {
            docia[i] = documentByteString.charCodeAt(i);
          }
          const docblob = new Blob([docab], { type: documentMimeString });
          reader.readAsDataURL(documentFileInner);

          newForm.append("document", docblob, documentFileInner.name);
        } else {
          newForm.append("document", formikData?.document);
        }
        newForm.append("name", name);
        newForm.append("identity", parseInt(identity));
        newForm.append("zodiac", profileData.zodiac);
        newForm.append("hoursPrice", hoursPrice);
        newForm.append("ethnicity", selectedEthnic || profileData.ethnicity);
        newForm.append('hashtags', JSON.stringify(selectedHashtags))

        function convertToBackendFormat(selectedLanguages) {
          // Extract just the value fields from each object and return as array
          return selectedLanguages.map(language => language.value);
        }

        // Example usage:
        const backendFormat = convertToBackendFormat(selectedLanguages);
        console.log(backendFormat, 'this is backendformat');
        newForm.append('languages', JSON.stringify(backendFormat))

        if (scanOutputData) {
          newForm.append("gender", scanOutputData?.gender?.toString() || "0");
        }
        // console.log("formdata is hereeee ---->>>>", formikData);

        // formData.append("profileImage", blob, file.name);
        try {
          const response = await profileComplete(newForm);
          console.log("profileComplete response ==>>", response);
          if (response.statusCode === 200) {
            notifySuccess(response.message);
            // handleClose();
            // setTimeout(() => {
            window.location.reload();
            navigate(-1);
            // }, 2000)
          }
        } catch (error) {
          console.error("Error uploading photo:", error);
          notifyError(error?.response?.data?.message || `Something went wrong`);
          // handleClose();
        } finally {
          setSubmitting(false);
        }
        // };

        // Read the file as a binary string
        // Use readAsDataURL to set image preview correctly
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleProceed = async () => {
    const newForm = new FormData();
    dataFromGemini.gender === 'Male'? newForm.append('gender', 0) : dataFromGemini.gender === 'Female'? newForm.append('gender', 1) : newForm.append("gender", 2);
    newForm.append('ethnicity', dataFromGemini.ethnicity);
    newForm.append('zodiac', dataFromGemini.zodiac);
    newForm.append('age', dataFromGemini.age);
    newForm.append("document",documentFile);
    newForm.append('identity',selectedDocumentType==="Driving License"?0:selectedDocumentType==="Passport"?1:selectedDocumentType==="Country ID"?2:1)

    try {
      
      const response = await profileComplete(newForm);
      const hastagresponse = await updateHashtags(formikData?.hoursPrice, selectedHashtags);
      console.log("profileComplete response ==>>", response);
      if (response.statusCode === 200) {
        notifySuccess(response.message);
        // handleClose();
        // setTimeout(() => {
        window.location.reload();
        navigate(-1);
        // }, 2000)
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
      // handleClose();
    }
  }



  const handleFileChange = async (event) => {
    // console.log("working================================here");
    setProfileImageLoading(true);
    try {
      const file = event.target.files[0];
      setImageFile(file);
      setImageError(false);
      console.log(file, "filefilefilefile");
      if (!file) {
        console.error("No file selected");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        const dataURL = reader.result;
        console.log(dataURL, "dataURL");
        setImageDataUrl(dataURL);
        // Convert data URL to Blob
        const byteString = atob(dataURL.split(",")[1]);
        const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        // Create form data object to send binary data
        const formData = new FormData();
        formData.append("files", blob, file.name);
        try {
          // Upload the file using the photoUpload function
          const imageUpload = await photoUpload(formData);
          if (imageUpload.statusCode === 200) {
            console.log('I am data of profile Upload', imageUpload);
            console.log(imageUpload.data.uploadedUrls[0], "imageUpload.data.uploadedUrls[0]");
            setImageUrl(imageUpload.data.uploadedUrls[0]);
            setImagePreview(imageUpload.data.uploadedUrls[0]);
            console.log(imageUrl, "imageUrl");


          }
        } catch (error) {
          console.error("Error uploading image:", error);
        } finally {
          setProfileImageLoading(false);
        }
      };
      // Read the file as a binary string
      reader.readAsDataURL(file); // Use readAsDataURL to set image preview correctly
    } catch (error) {
      setProfileImageLoading(false);
      console.error("Error uploading file:", error);
    } finally {
      // setProfileImageLoading(false);
    }
  };

  console.log('I am the selected Document type', selectedDocumentType);

  //  document change handler


  const closeScanOutputModel = () => {
    setScanOutputModel(false);
  }









  const handleFileUpload = async (event) => {
    setLoading(true)
    try {
      notifySuccess('Document Verification in progress , please wait for response!!');
      const file = event.target.files[0];
      setDocumentFile(file);
      const data = await getDocumentDetailsFromGemini(file);
      console.log('I am data from gemini!!!!!!!', data);
      let jsonPart

      try {
        jsonPart = data.substring(
          data.indexOf('{'),
          data.lastIndexOf('}') + 1
        );

        // You can continue processing the jsonPart here if needed

      } catch (error) {
        // Show a toast message when an error occurs
        notifyError("An error occurred while verifying the document, please reload and try again!!");

        // Return from the function to prevent further execution
        return;
      }
      console.log(jsonPart, 'I am jsonpart')

      const cleandata = JSON.parse(jsonPart);
      console.log(cleandata, 'I am clean data object');


      setDataFromGemini(cleandata);
      setDocumentError(false);
      if (!file) {
        console.error("No file selected");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        const dataURL = reader.result;
        console.log(dataURL, "dataURL");
        setDocumentDataUrl(dataURL);
        // Convert data URL to Blob
        const byteString = atob(dataURL.split(",")[1]);
        const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        // Create form data object to send binary data
        const formData = new FormData();
        formData.append("document", blob, file.name);
        formData.append("type", 0);
        try {
          // Upload the file using the photoUpload function
          const response = await scanDocument(formData);
          console.log(response, "imageUpload in scanDocument");
          setScanDocumentStatusCode(response.statusCode);
          if (response.statusCode === 200) {
            setScanOutputModel(true);
            setScanOutputData(response?.data)

          }
        } catch (error) {
          console.error("Error uploading image:", error);
        } finally {
          setLoading(false)
        }
      };
      // Read the file as a binary string
      reader.readAsDataURL(file); // Use readAsDataURL to set image preview correctly
    } catch (error) {
      setLoading(false)

      console.error("Error uploading file:", error);
    } finally {
      // setLoading(false)
    }
  };



  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);



  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getMyProfile();
        console.log(data.data.user, "datadatadata");
        setFormikData(data.data.user);
        setProfileData(data.data.user);
        console.log('I am profile Data in complete profile', data.data.user);
      } catch (err) {
        // setError(err.message);
        // setLoading(false);
      }
    };
    fetchProfile();
    getethnics();
    listlanguages();
    setSelectedEthnic(formikData?.ethnicity);
    setselectedDocumentType(formikData?.identity===0?'Driving License':formikData?.identity===1?'Passport':'Country ID');

  }, []);

  useEffect(()=>{
    setselectedDocumentType(formikData?.identity===0?'Driving License':formikData?.identity===1?'Passport':'Country ID');
  },[formikData])

  // if (loading) {
  //   return <div><Loaders /></div>
  // }


  console.log(selectedEthnic, 'I am selected ethenic');
  console.log(selectedLanguages, ' We are selected langagues');

  async function getethnics() {
    const data = await listEthnics();
    setEthnicities(data.data.ethnics);
    console.log(data, 'I am ethnecis data');
  }


  async function listlanguages() {
    const data = await listLanguages();
    console.log(data, 'I am language data');
    setLanguages(data.data.languages);
  }



  useEffect(() => {
    if (formikData?.languages) {
      const formattedLanguages = formikData.languages.map(lang => ({
        label: lang.name,
        value: lang._id
      }));
      setSelectedLanguages(formattedLanguages);
    }
    setSelectedEthnic(formikData?.ethnicity)

  }, [formikData]);


  async function getDocumentDetailsFromGemini(documentFile) {
    try {
      // Convert the uploaded file to base64
      const base64Document = await fileToBase64(documentFile);

      // Initialize Gemini API
      const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);
      const model = genAI.getGenerativeModel({ model: "gemini-2.5-pro-exp-03-25" });

      // Generate content with the document image
      const result = await model.generateContent([
        {
          inlineData: {
            data: base64Document,
            mimeType: documentFile.type,
          },
        },
        `I am implementing feature in my app to autofill some data for my users when they upload their document, give me name, gender and date of birth from my document, if gender not mentioned from it's name make an intelligent guess, also its ethnicity, zodiac and age and organize data in javascript object format so it is easy for me to access that is in key value pairs, also don't return javscript makrers only return pure javascript object,also don't give any comments inside object, also make sure your response is consistent means key for gender will be gender and for zodiac it will be zodiac for ethnicity it will be ethnicity and for age it will be age and their values ofcourse which you find in image,Additionally, please verify: Is this document a ${selectedDocumentType}? 
In your response, include an "isCorrectDocumentType" field with a boolean value (true/false) 
indicating whether the document matches the specified type. Also be very careful with your response you cannot say true in cases like i person sends country id and type as driving licence ,be very careful of these cases do not make mistakes,also make sure when you send gender always send complete like Male or Female don't send M or F `,
      ]);

      console.log(result.response.text(), 'I am fetched data from gemini');
      return result.response.text();
    } catch (error) {
      console.error("Error processing document with Gemini:", error);
      throw error;
    }
  }

  // Helper function to convert file to base64
  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Extract base64 data from the result (remove the data URL prefix)
        const base64Data = reader.result.split(',')[1];
        resolve(base64Data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const handleUpdateName=async()=>{
    let formData= new FormData();
    formData.append('name',username);

    const response= await updateProfile(formData);
    if(response.statusCode===200){
      // notifySuccess('Updating name and reloading!!');
      setTimeout(() => {
        navigate('/complete-profile')
      }, 1000);
    }
   
  }

  useEffect(() => {
    console.log("Gender updated:", dataFromGemini.gender);
  }, [dataFromGemini.gender]);


  // const handleEditGender=(e)=>{
  //      setDataFromGemini((prev)=>({
  //       ...prev,
  //       gender:e.target.value
  //      }))
  // }

  const handleGenderChange=(e)=>{
    const { name, value } = e.target;

    // Validate gender input
    if (name === "gender") {
      const validGenders = ["Male", "Female", "Other"];
      
      // Allow typing but show error only for invalid full inputs
      if (value && !validGenders.some((gender) => gender.startsWith(value))) {
        notifyError("Enter a valid gender (Male, Female, Other)");
        return; // Ignore invalid input
      }
    }
  
    // Validate age input: allow only numbers
    if (name === "age") {
      if (!/^\d*$/.test(value)) {
        notifyError("Only numbers are allowed for age");
        return; // Ignore non-numeric input
      }
    }

    const validZodiacs = [
      "Aries", "Taurus", "Gemini", "Cancer", "Leo", 
      "Virgo", "Libra", "Scorpio", "Sagittarius", 
      "Capricorn", "Aquarius", "Pisces"
    ];
  
    // Allow partial input but validate full input
    if (name === "zodiac") {
      if (value && !validZodiacs.some((zodiac) => zodiac.toLowerCase().startsWith(value.toLowerCase()))) {
        notifyError("Enter a valid zodiac sign");
        return; // Ignore invalid input
      }
    }
  
    setDataFromGemini((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }


  return (
    <div className={`container-fluid theme-switcher ${theme === "dark" ? "black-color" : "light-theme"
      }`}>
      <div
        className={`container theme-switcher ${theme === "dark" ? "black-color" : "light-theme"
          }`}
      >
        <div className="row">
          <div className="col-md-6 d-flex justify-content-center align-items-center px-5">
            <div className="text-center">
              <img src={Image} alt="" />
              <p className="the-details pt-4">
                Enter the details to setup the responders profile
              </p>
            </div>
          </div>

          <div
            className={`col-md-6  m-auto theme-switcher ask-to-sign-scroll-hide ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
              }`} style={{ overflowY: "scroll", height: '100vh' }}
          >
            <div className="ask-to-sign  px-3">
              <h2 className="d-flex mt-4 pt-5">
                Setup Responders Profile <img src={logo} alt="" />
              </h2>

              {formikData && (
                <Formik
                  initialValues={{
                    name: formikData?.name || "",
                    identity: formikData?.identity ? formikData?.identity.toString() : "0",
                    hoursPrice: formikData?.hoursPrice || "",
                    ethnicity: formikData?.ethnicity || ''

                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                >
                  {({
                    formikProps,
                    isSubmitting,
                    values,
                    setFieldValue,
                    errors,
                  }) => (
                    <FormikForm className="mt-4 d-grid gap-2">
                      <center className="pt-4">
                        <input
                          type="file"
                          id="profileImage"
                          name="profileImage"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                        <label htmlFor="profileImage">
                          {imagePreview || formikData?.profileImage ? (
                            <img
                              style={{ marginTop: "-25px" }}
                              src={imagePreview || formikData?.profileImage}
                              alt="Preview"
                              className="side-bar-profile object-fit-cover"
                            />
                          ) : (
                            <Button
                              component="span"
                              role={undefined}
                              variant="contained"
                              tabIndex={-1}
                              className="upload-file"
                              style={{ marginTop: "-25px" }}
                            >
                              <FontAwesomeIcon
                                icon={faPhotoFilm}
                                className="upload-file-icon mt-4 pt-1"
                              />
                            </Button>
                          )}
                        </label>
                        {/* {imageError ? (
                        <span className="text-danger">Profile image required</span>
                      ) : (
                        ""
                      )} */}
                        <br />
                        <Form.Label
                          htmlFor="profileImage"
                          className={`form-label mt-2  theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                            }`}
                        >
                          Profile Picture
                        </Form.Label>
                      </center>

                      {/* Conditionally render imagePreview */}
                      <Form.Group>
                        <Form.Label
                          className={`form-label  theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                            }`}
                        >
                          Full Name
                        </Form.Label>
                        <Field
                          type="text"
                          name="name"
                          onChange={(e) => {
                            // Update Formik's value
                            setFieldValue('name', e.target.value);

                            // Also update your state with the text content
                            setUsername(e.target.value);
                          }}
                          placeholder="Enter Full Name"
                          className={`form-control form-input  theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                            }`}
                        />
                        {username!==''?<button onClick={()=>handleUpdateName()}>Update✅</button>:<></>}
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label
                          className={` ' form-label mt-2  theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                            }`}
                        >
                          Authenticate Your Identity
                        </Form.Label>
                        <Field
                          as="select"
                          name="identity"
                          className={`form-control form-input custom-select theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                            }`}
                          onChange={(e) => {
                            // Update Formik's value
                            setFieldValue('identity', e.target.value);
                            

                            // Also update your state with the text content
                            const selectedText = e.target.options[e.target.selectedIndex].text;
                            setselectedDocumentType(selectedText);
                          }}
                        >
                          <option value="0">Driving License</option>
                          <option value="1">Passport</option>
                          <option value="2">Country ID</option>
                        </Field>
                        <Box
                          sx={{
                            p: 2,
                            borderRadius: 7,
                            backgroundColor: theme === "dark" ? "#333" : "#f9f9f9",
                            color: theme === "dark" ? "#fff" : "#000",
                            textAlign: "center",
                            width: "100%",
                            margin: "0 auto",
                          }}
                          className="mt-4"
                        >


                          <Box
                            sx={{
                              position: "relative",
                              borderRadius: 7,
                              p: 4,
                              backgroundColor: "#e0e0e0",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              height: 200,
                              overflow: "hidden",
                            }}
                          >
                            {documentDataUrl || formikData?.document ? (
                              <img
                                src={documentDataUrl || formikData?.document}
                                alt="Uploaded Preview"
                                style={{
                                  maxWidth: "100%",
                                  //   maxHeight: "100%",
                                  borderRadius: 8,
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <label htmlFor="upload-file" style={{ cursor: "pointer" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* <Upload sx={{ fontSize: 50, color: "#aaa" }} />
              <p style={{ margin: 0, fontSize: 14, color: "#666" }}>
                Upload File
              </p> */}
                                  <img src={UploadFile2} alt="" className="m-auto" />
                                </Box>
                              </label>
                            )}
                            <input
                              type="file"
                              id="upload-file"
                              onChange={handleFileUpload}
                              style={{ display: "none" }}
                            />
                            <IconButton
                              sx={{
                                position: "absolute",
                                bottom: 15,
                                right: 15,
                                width: 50,
                                height: 50,

                                background: "linear-gradient(45deg, #F10B80, #830D9A)",
                                color: "#fff",
                                boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                              }}
                              component="label"
                            >
                              <input
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={handleFileUpload}
                              />
                              <img
                                src={imagePreview ? UploadFile3 : UploadFile}
                                alt=""
                                className="m-auto"
                                style={{ width: "18px" }}
                              />
                            </IconButton>
                          </Box>
                        </Box>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label
                          className={`form-label mt-3  theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                            }`}
                        >
                          Hourly Price
                        </Form.Label>

                        <TextField
                          type="number"
                          className={`  theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                            }`}
                          onWheel={(e) => e.target.blur()}
                          name="hoursPrice"
                          defaultValue={formikData?.hoursPrice || 0}
                          onChange={(e) => setFieldValue('hoursPrice', e.target.value)}
                          // placeholder="Enter Hourly Price"

                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" sx={{ color: theme === "dark" ? "#fff" : "#888" }} >
                                $
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end" sx={{ color: theme === "dark" ? "#fff" : "#888" }} >
                                /hr
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          sx={{
                            borderRadius: 20,
                            backgroundColor: theme === "dark" ? "#333" : "#fff", // Dark mode support
                            color: theme === "dark" ? "#fff" : "#000",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: 20,
                              padding: "0 8px", // Adjust padding for height
                              "& fieldset": {
                                borderColor: "#ccc",
                              },
                              "&:hover fieldset": {
                                borderColor: "#aaa",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#f10b80",
                                boxShadow: "0 0 0 0.25rem #f10b8048",
                                color: theme === "dark" ? "#fff" : "#000",
                              },
                            },
                            "& input": {
                              textAlign: "center",
                              color: theme === "dark" ? "#fff" : "#000",
                              padding: "14px 0px", // Adjust padding for the input
                            },
                          }}

                        />
                        <ErrorMessage
                          name="hoursPrice"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label
                          className={`form-label mt-2 theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"}`}
                        >
                          Ethnicity
                        </Form.Label>
                        <Field
                          as="select"
                          name="ethnicity"
                          className={`form-control form-input custom-select theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"}`}
                          onChange={(e) => {
                            // Update Formik's internal state
                            setFieldValue('ethnicity', e.target.value);
                            // Also update your separate state
                            setSelectedEthnic(e.target.value);
                          }}
                        >
                          <option value="">-- Select an ethnicity --</option>
                          {ethnicities.map(ethnic => (
                            <option key={ethnic._id} value={ethnic.ethnic}>
                              {ethnic.ethnic}
                            </option>
                          ))}
                        </Field>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label
                          className={`form-label mt-2 theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"}`}
                        >
                          Languages
                        </Form.Label>

                        <MultiSelect

                          options={languages.map(language => ({
                            label: language.name,
                            value: language._id
                          }))}
                          value={selectedLanguages}
                          onChange={setSelectedLanguages}
                          labelledBy="Select Languages"
                          className={`theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"} custom-multi-select language-multi-select`}
                          valueRenderer={customValueRenderer}
                          styles={customStyles}


                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label
                          className={` ' form-label mt-2  theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                            }`}
                        >
                          Add Hashtag
                        </Form.Label>
                        <Hashtags handleSubmitHashtags={handleHashtagsChange} initialHashtags={formikData?.hashtags} />
                      </Form.Group>

                      <div className="d-grid gap-2 ">


                        {loading || profileImageLoading ?
                          <button className="sign-in-btn mt-4 mb-5 py-3" disabled={true}>
                            {loading ? `uploading identity document...` : profileImageLoading ? `uploading profile image...` : ``}
                          </button> :
                          isSubmitting ?
                            <button className="sign-in-btn mt-4 mb-5 py-3" disabled={true}>
                              {`updating...`}
                            </button>
                            :
                            <button className="sign-in-btn mt-4 mb-5 py-3">
                              Complete profile
                            </button>}
                      </div>
                    </FormikForm>
                  )}
                </Formik>)}
            </div>
          </div>
        </div>
      </div>
      {Object.keys(dataFromGemini).length !== 0 &&dataFromGemini.isCorrectDocumentType&&dataFromGemini.name.toLowerCase()===formikData?.name.toLowerCase()?
        (<div class="css-overlay">
          <div class="css-modal">
            <div class="css-header">
              <span class="css-check-icon">✓</span>
              <span class="css-title">ID Authenticated</span>
            </div>

            <p class="css-subtitle">Ask Human will autofill your mentioned data.</p>

            <div class="css-input-fields">
              <input type="text" value={`${dataFromGemini.gender
                }`}  onChange={handleGenderChange} name="gender" placeholder="Enter Male or Female or other"
                />
              <input type="text" value={dataFromGemini.
                ethnicity} name='ethnicity' onChange={handleGenderChange}/>
              <input type="text" value={dataFromGemini.zodiac
              } name="zodiac" onChange={handleGenderChange}/>
              <input type="number" value={dataFromGemini.age} name="age" onChange={handleGenderChange}  placeholder="Enter Age"
    min="0" />
            </div>

            <button class="css-proceed-btn" onClick={() => {
              
               handleProceed();
              setDataFromGemini({})
             



            }}>Proceed</button>
          </div>
        </div>):Object.keys(dataFromGemini).length !== 0?(<div class="css-overlay">
          <div class="css-modal">
            <div class="css-header">
              <span class="css-check-icon">❌</span>
              <span class="css-title">ID Authentication failed</span>
              <span>Either your selected document type doesn't match your uploaded document or your uploaded document does'nt match your own name</span>
            </div>
            <button class="css-proceed-btn" onClick={() => {
              setDataFromGemini({})
              setDocumentFile(null);
              notifyError('Upload document again , uploaded file will not be saved');
              window.location.reload();
              
            }}> Reload and Try Again</button>
          </div>
        </div>):<></>}


    </div >
  );
}

export default Index;
