import React, { useState } from "react";

const GoogleVisionAPI = () => {
  const [image, setImage] = useState(null);
  const [result, setResult] = useState(null);

  const GOOGLE_API_KEY = "YOUR_GOOGLE_API_KEY"; // Replace with your API key

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const analyzeImage = async () => {
    if (!image) {
      alert("Please an image first.");
      return;
    }

    const base64Image = image.split(",")[1]; // Remove metadata prefix

    const requestBody = {
      requests: [
        {
          image: { content: base64Image },
          features: [{ type: "FACE_DETECTION", maxResults: 1 }],
        },
      ],
    };

    try {
      const response = await fetch(
        `https://vision.googleapis.com/v1/images:annotate?key=${GOOGLE_API_KEY}`,
        {
          method: "POST",
          body: JSON.stringify(requestBody),
          headers: { "Content-Type": "application/json" },
        }
      );

      const data = await response.json();

      if (data.responses[0].faceAnnotations) {
        const faceData = data.responses[0].faceAnnotations[0];

        // Estimating Age based on Joy & Surprise confidence
        const ageEstimate =
          faceData.joyLikelihood === "VERY_LIKELY"
            ? "Young"
            : faceData.surpriseLikelihood === "LIKELY"
            ? "Teenager"
            : "Adult";

        setResult({
          age: ageEstimate,
          gender: "Unknown (Google does not provide gender)", // Google Vision API does not return gender directly
        });
      } else {
        alert("No face detected.");
      }
    } catch (error) {
      console.error("Error analyzing image:", error);
    }
  };

  return (
    <div>
      <h2>Detect Age & Gender using Google Vision API</h2>
      <input type="file" accept="image/*" onChange={handleImageChange} />
      <br />
      {image && <img src={image} alt="Uploaded" style={{ width: "300px" }} />}
      <br />
      <button onClick={analyzeImage}>Analyze Image</button>
      {result && (
        <div>
          <p>Estimated Age: {result.age}</p>
          <p>Gender: {result.gender}</p>
        </div>
      )}
    </div>
  );
};

export default GoogleVisionAPI;
