import React, { useContext, useEffect, useState } from "react";
import logo from "../../../Images/asknewlogo__1_-removebg-preview.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import google from "../../../Images/google.png";
import apple from "../../../Images/apple.png";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { register, socialLogin } from "../../../services/index";
import { updateProfile } from "../../../services/api/userApi";
import { ToastContainer } from "react-toastify";
import { notifySuccess, notifyError } from "../../../toastNotifications";
import { socket } from "../../../context/SocketMessageContext";
import { signInWithGooglePopup } from "../../../firebase/config"; // Adjust the path based on your actual folder structure
import xmark from "../../../Images/x-mark.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import NavbarSignFlow from '../../Login/NavbarSignFlow';
import CustomDateFieldWithSelector from "../Create-profile/CustomDateFieldWithSelector";
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameMonth,
  isSameDay,
  differenceInYears,
  setYear,
  getYear,
} from "date-fns";
import usernamesvg from "../../../Images/signupsvgs/usernamesvg.svg"
import emailsvg from "../../../Images/signupsvgs/email.svg"
import phonenumbersvg from "../../../Images/signupsvgs/phonenumber.svg"
import passwordsvg from "../../../Images/signupsvgs/shield.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";




const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.(?:com|in|net|org|edu|gov|co\.uk)$/;

const validationSchema = Yup.object().shape({
  name: Yup.string()
  .matches(/^[A-Za-z\s]+$/, "Name can only contain letters and spaces")
  .required("Name is required"),
  // email: Yup.string()
  //   .matches(emailRegex, "Invalid email format")
  //   .required("Email is required!"),
  age: Yup.number().positive("positive number allowed only")
    .min(18, 'Minimum age 18 required')
    .max(72, 'Minimum age 72 allowed')
    .required('Age is required'),
  dob: Yup.string().trim()
    .test('notEmptyAfterTrim', 'Dob is required', value => value.trim() !== '')
    .required("Dob is required"),
  // countryCode: Yup.string().trim()
  // .test('notEmptyAfterTrim', 'Country code is required!', value => value.trim() !== '')
  // .matches(/^[0-9]+$/, "Country code must contain only digits")

  // mobile: Yup.string()
  //   .trim()
  //   .test(
  //     "notEmptyAfterTrim",
  //     "Phone number is required!",
  //     (value) => value.trim() !== ""
  //   )
  //   .matches(/^[0-9]+$/, "Number must contain only digits")
  //   .min(7, "Phone number must be at least 7 digits")
  //   .max(15, "Phone number must not exceed 15 digits")
  //   .required("Phone number is required!"),

  // password: Yup.string()
  //   .trim()
  //   .test(
  //     "notEmptyAfterTrim",
  //     "Password is required!",
  //     (value) => value.trim() !== ""
  //   )
  //   // .min(6, "Password must be at least 6 characters long")
  //   .required("Password is required!")
  //   .min(10, "Password must be at least 10 characters long")
  //   .matches(/[a-z]/, "Password must contain at least one lowercase letter")
  //   .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
  //   .matches(/[0-9]/, "Password must contain at least one number")
  //   .matches(
  //     /[@$!%*?&#]/,
  //     "Password must contain at least one symbol (@$!%*?&#)"
  //   )
  //   .required("Password is required"),

  // confirmPassword: Yup.string()
  //   .oneOf([Yup.ref("password"), null], "Passwords must match")
  //   .required("Confirm Password is required!"),
});

function Index() {
  const navigate = useNavigate();
  // const socket = useContext(socketContext)
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [modelOpenStatus, setModelOpenStatus] = useState(false);
  const [countryCode, setCountryCode] = useState("91");
  const [phoneNumber, setPhoneNumber] = useState(null);

  const handleOpen = () => setModelOpenStatus(true);
  const handleClose = () => {
    setModelOpenStatus(false);
    navigate(`/otp-verification-phone/${phoneNumber}`);
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithGooglePopup();
      const user = result.user;
      console.log("Logged in user:", user);
      if (user && user?.uid) {
        let data = {
          socialId: user?.uid,
          socialType: "google",
          email: user?.email,
          fcmToken: "web",
        };
        try {
          const response = await socialLogin(data);
          console.log("socialLogin RESPONSE here :", response);
          if (response.statusCode === 200) {
            socket.emit("addUser", response.data.user._id);
            // notifySuccess("Logged in successfully!");
            if (response.data.user.profileComplete == 0) {
              // navigate("/create-profile");
              navigate('/finish-profile-setup')

            } else {
              navigate("/home"); // Redirect to the home page
            }
          }
        } catch (error) {
          console.log("socialLogin ERROR here :", error);
          notifyError(error?.response?.data?.message || `Something went wrong`);
        }
      } else {
        throw new Error("No user found");
      }
      // Handle successful login
    } catch (error) {
      console.error("Google Sign-In Error:", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfPasswordVisibility = () => {
    setShowConfPassword(!showConfPassword);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("ooooooooooooooooooooooooo")
    if (!countryCode || countryCode.trim() == "") {
      notifyError("Please select country code");
      return;
    } else {
      const { name, age, dob, email, password,
        // confirmPassword, 
        mobile } = values;
      try {
        const formData = {
          name,
          age,
          dob,
          // email,
          // password,
          // countryCode: `+${countryCode}`,
          // mobile,
          // confirmPassword,
          // fcmToken: "web",
        };
        console.log(formData,"formDataformDataformDataformData")
        const response = await updateProfile(formData);
        console.log("sign-up res", response);
        if (response.statusCode === 200) {
          navigate("/search-note"); // Redirect to the home page
          window.location.reload();
          // localStorage.setItem("token", response.data.token);
          // localStorage.setItem("mobileOtp", response.data.mobileOtp);
          // localStorage.setItem("user", JSON.stringify(response.data.user));
          // localStorage.setItem(
          //   "stripeCustomer",
          //   JSON.stringify(response.data.stripeCustomer)
          // );
          // // socket.emit('addUser', { userId: response.data.user._id });
          // socket.emit("addUser", response.data.user._id);

          // // notifySuccess(response.message);
          // handleOpen();
          // setPhoneNumber(mobile);
          // notifySuccess("Kindly check your mail and verify it, and complete your profile now.");
          // setTimeout(() => {
          //   navigate(`/otp-verification-phone/${mobile}`)
          // }, 5000)
        }
      } catch (error) {
        if (error.response && error.response.data) {
          const { status, message, statusCode } = error.response.data;
          console.log("Status:", status);
          console.log("Message:", message);
          console.log("StatusCode:", statusCode);
          notifyError("Signup failed: " + message);
        } else {
          notifyError("Signup failed: " + error.message);
        }
      } finally {
        setSubmitting(false);
      }
    }
  };
 
  
  useEffect(() => {
    const userAgent = navigator.userAgent;
    setIsSafari(/^((?!chrome|android).)*safari/i.test(userAgent));
    // setIsChrome(/chrome/i.test(userAgent));
  }, []);

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  // console.log("countryCode", countryCode);


  const calculateAge = (dob) => {
    if (!dob) return "";
    return differenceInYears(new Date(), dob);
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: '',
          email: "",
          password: "",
          // confirmPassword: "",
          mobile: "",
          age: 0,
          dob: ""
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setValues, setFieldValue }) => (
          <Form>
            {/* <NavbarSignFlow /> */}
            <div
              className={` ' container-fluid theme-switcher ${theme === "dark" ? "black-color" : "light-theme"
                }`}
            >
              <div className="row">
                <div className="col-md-7 login-left-section d-flex px-5">
                  <div className="justify-content-center align-self-center">
                    <h2>International ask Question with Ask Human!</h2>
                    <p>Enter your name and DOB to register your account .</p>
                  </div>
                </div>
                <div
                  className={` ' col-md-5 login-right-section m-auto theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                    }`}
                >
                  <button className={` ' back-slide mt-3 ml-2 theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                  }`} onClick={() => navigate(-1)}>
                                           <FontAwesomeIcon icon={faArrowLeft} /> Back
                                            </button>
                  {/* <p className="p-4 login-right-section-p">
                    Already have an account?{" "}
                    <span>
                      <b>
                        <Link
                          to="/login"
                          className={` '  sign-up-text text-dark theme-switcher ${
                            theme === "dark"
                              ? "dark-mode-pink-color"
                              : "light-theme"
                          }`}
                        >
                          Sign in
                        </Link>
                      </b>
                    </span>
                  </p> */}
                  <div className="ask-to-sign px-5 pb-4">
                    <h2 className="d-flex pt-3">
                      Sign up to Ask Human! 
                    </h2>
                    <img
                src={logo}
                alt="Ask Human Logo"
                className="m-auto pt-3 pb-2"
              />
                <p  className={` ' back-to-ask-human pt-3 pb-3 theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                    }`}>Join Ask Human – Get real answers from real people!</p>
                    {/* <div className="mt-3">
                      <label className={`form-label  theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>User Name</label>
                      <Field
                        type="text"
                        name="name"
                        placeholder="Enter Name"
                        className={`form-input mt-1 theme-switcher ${theme === "dark"
                          ? "dark-theme-only-color"
                          : "light-theme"
                          }`}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-danger"
                      />
                    </div> */}

                    <div className="mt-3">
                      <label className={`form-label  theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Full Name</label>
                      <div className="css-input-container">
                        <img src={usernamesvg} alt="" className="css-input-icon" />
                        <Field
                          type="text"
                          name="name"
                          placeholder="Enter Name"
                          className={`css-form-input ps-5 form-input theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                            }`}
                        />

                      </div>
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* <div className="mt-3">
                      <label
                        htmlFor="email"
                        className={`form-label theme-switcher ${theme === "dark"
                          ? "dark-theme-only-white"
                          : "light-theme"
                          }`}
                      >
                        Email
                      </label>
                      <div className="css-input-container">
                        <img src={emailsvg} alt="" className="css-input-icon" style={{ top: '22px' }} />
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Enter Your Email"
                          className={`css-form-input form-input mt-1 theme-switcher ${theme === "dark"
                            ? "dark-theme-only-color"
                            : "light-theme"
                            }`}
                        />
                      </div>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div> */}

                    {/* <div className="mt-3 d-flex ">
                      <div className="w-100">
                        <label
                          htmlFor="mobile"
                          className={`form-label theme-switcher ${theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                            }`}
                        >
                          Phone number
                        </label>
                        <div className="d-flex gap-2">
                          <PhoneInput
                            country={"in"}

                            enableSearch={true}
                            enableLongNumbers={true}
                            value={countryCode}
                            onChange={(countryCode) =>
                              setCountryCode(countryCode)
                            }
                            inputStyle={{
                              background: theme === "dark" ? "black" : "unset",
                              borderRadius: `50px`,
                              width: `135px`,
                              padding: `12.5px 14px 12.5px 60px`,
                              border: !countryCode
                                ? `2px solid #ff4949`
                                : `1px solid #CACACA`,
                            }}
                            inputProps={{
                              readOnly: true, // Prevent typing in the input field
                            }}
                            countryCodeEditable={false}
                            dropdownStyle={{
                              backgroundColor: theme === "dark" ? "black" : "white",
                            }}
                          />
                          <div className="css-input-container w-75">
                            <img src={phonenumbersvg} alt="" className="css-input-icon" />
                            <Field
                              type="text"
                              id="mobile"
                              name="mobile"
                              placeholder="Enter Your Phone Number"
                              className={`css-form-input  form-input theme-switcher ${theme === "dark"
                                ? "dark-theme-only-color"
                                : "light-theme"
                                }`}
                              onKeyPress={(e) => {
                                if (!/^\d$/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          name="mobile"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div> */}

                    {/* DATE OF BIRTH FIELD STARTS HERE*/}

                    <div className="mt-3" >
                      <label className={`form-label mt-2  theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Date of birth</label>
                      <CustomDateFieldWithSelector
                   
                           onChange={(e) => {
                        // console.log('Date of birth', e)
                        let calculatedAge = calculateAge(e) || 0;
                        // console.log('calculatedAge', calculatedAge)

                        let yyyymmdd = e ? format(e, "yyyy-MM-dd") : '';
                        // setFieldValue('age', calculatedAge)
                        // setFieldValue('dob', yyyymmdd)
                        setValues((prevValues) => ({
                          ...prevValues,
                          age: calculatedAge,
                          dob: yyyymmdd
                        }));

                      }}
                        max={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
                      // max={new Date(new Date().setFullYear(new Date().getFullYear() - 18, 6))}

                      />
                      <ErrorMessage
                        name="dob"
                        component="div"
                        className="text-danger"
                      />
                      <ErrorMessage
                        name="age"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* DATE OF BIRTH FIELD ENDS HERE*/}


                    {/* <div className="mt-3">
                      <label
                        htmlFor="password"
                        className={` ' form-label theme-switcher ${theme === "dark"
                          ? "dark-theme-only-white"
                          : "light-theme"
                          }`}
                      >
                        Password
                      </label>

                      <div className="password-input-container relative">
                        <img src={passwordsvg} alt="" className="css-input-icon" style={{ top: '14px' }} />

                        <Field
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          placeholder="Enter Your Password"
                          className={`css-form-input form-input mt-1 theme-switcher ${theme === "dark"
                            ? "dark-theme-only-color"
                            : "light-theme"
                            }`}
                        />
                        <span
                          onClick={togglePasswordVisibility}
                          className="toggle-password-icon"
                        >
                          {!showPassword ? <FiEyeOff /> : <FiEye />}
                        </span>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger"
                      />
                    </div> */}
                    {/* <div className="mt-3">
                      <label
                        htmlFor="confirmPassword"
                        className={` ' form-label theme-switcher ${theme === "dark"
                          ? "dark-theme-only-white"
                          : "light-theme"
                          }`}
                      >
                        Confirm Password
                      </label>
                      <div className="password-input-container">
                        <Field
                          type={showConfPassword ? "text" : "password"}
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder="Enter Confirm Password "
                          className={` ' form-input mt-1 theme-switcher ${theme === "dark"
                            ? "dark-theme-only-color"
                            : "light-theme"
                            }`}
                        />
                        <span
                          onClick={toggleConfPasswordVisibility}
                          className="toggle-password-icon"
                        >
                          {!showConfPassword ? <FiEyeOff /> : <FiEye />}
                        </span>
                      </div>
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="text-danger"
                      />
                    </div> */}
                    {/* {isSubmitting ? (
                      <button
                        type="submit"
                        className="sign-in-btn mt-4"
                        disabled={true}
                      >
                        Signing up...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="sign-in-btn mt-4"
                        disabled={isSubmitting}
                      >
                        Sign up
                      </button>
                    )} */}

<button         type="submit"
                        className="sign-in-btn mt-4"
                        disabled={isSubmitting}>Sign up</button>
                    {/* <p className="continue-with mt-4">Or continue with</p>
                    <center className="button-center d-flex mt-4 mb-5">
                      <button
                        className={
                          isSafari
                            ?
                            `google-btn mx-2`
                            : `google-btn mx-2 w-100 d-flex justify-content-center`
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          signInWithGoogle();
                        }}
                      >
                        <img src={google} alt="Google" /> {"\u00A0"}Google
                      </button>
                      {isSafari ? (
                        <button className="google-btn mx-2">
                          <img src={apple} alt="Apple" /> {"\u00A0"}Apple
                        </button>
                      ) : (
                        ""
                      )}
                    </center> */}

                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div>
        <Modal
          open={modelOpenStatus}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            className={` ' inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === "dark" ? "black-color" : "light-theme"
              }`}
          >
            <div className="d-grid">
              <div className="d-flex justify-content-center">
                <img src={logo} alt="" className="h-75" />
              </div>
              <p
                className={`text-center py-2 px-4 theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                  }`}
              >
                A verification email has been sent. Please check your email and
                click on the verification link to complete the signup process.
              </p>
            </div>
            <center className="mt-4">
              <button className="logout-btn mx-2 w-25" onClick={handleClose}>
                Ok
              </button>
            </center>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default Index;
