import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import ChangePasswordModal from "../ChangePasswordModal";
import EditProfileModal from "../EditProfileModal";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ChangePhoneModal from "../ChangePhoneModal";
import { notifyError, notifySuccess } from '../../../toastNotifications';
// import image from '../../../Images/transformed-(1) 1.png';
import image from '../../../Images/delete account.png';
import { logout,deleteAccount } from "../../../services/index";
import xmark from '../../../Images/x-mark.png';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,

};
const Overview = () => {
const [open, setOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const hanldeLogout = async () => {
    try {
      const response = await logout();
      console.log("logout res -->", response);
      if (response.statusCode === 200) {
        localStorage.clear();
        window.location.href = "/home";
        notifySuccess("Logout successfully");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const { status, message, statusCode } = error.response.data;
        console.log("Status:", status);
        console.log("Message:", message);
        console.log("StatusCode:", statusCode);
        notifyError("Logout failed: " + message);
      } else {
        notifyError("Logout failed: " + error.message);
      }
    }
  };
  
  const handleDeleteAccount = async () => {
    try {
      const response = await deleteAccount();
      console.log("deleteAccount response -->", response);
      if (response.statusCode === 200) {
        localStorage.clear();
        window.location.href = "/home";
        notifySuccess("Account deleted successfully");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const { message } = error.response.data;
        console.log("Error:", message);
        notifyError("Account deletion failed: " + message);
      } else {
        notifyError("Account deletion failed: " + error.message);
      }
    }
  };
  

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (

    <div className="container ">
      <div className="row px-3 row-top-margin p-5 pt-0">
      <div  className={` ' inner-shadow theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}>


        <div
          className=" pb-2 mx-3 pt-2 text-center mb-5 mt-4"
          id="wallet-section-two"
        >
          <h3  className={` ' overview-heading-wallet theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}>Delete account</h3>
          <center>
            <img src={image} alt="" className='mt-4'/>
          </center>
          <p className="mt-3 text-start">
          <strong>Introduction</strong>
        </p>
        <ul className='delete-account text-start pt-2'>
    <li>Deleting your account is permanent and cannot be undone.</li>
    <li>All data, including conversations and account settings, will be permanently deleted.</li>
    <li>Settle any pending payments before proceeding.</li>
    <li>Consider logging out of your account instead if you’re unsure.</li>
    <li>Contact support for assistance if needed.</li>
    <li>Confirm deletion by entering your password.</li>
</ul>

<center className='mt-5'>
            <button className='delete-btn mx-2' onClick={handleOpen}>Delete</button>
            <button className='logoutt-btn mx-2' onClick={hanldeLogout}>Log Out</button>
          </center>
        </div>
      </div>
    </div>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={` 'inner-shadow p-0 border-0 border-rduis theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
        <img 
  src={xmark} 
  alt="" 
  className="x-mark m-2" 
  onClick={handleClose} 
  style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }} 
/> {/* Add onClick handler here */}
        
          <Typography id="modal-modal-title" variant="h6" component="h2" className='are-you-sure-heading p-3'>
          Delete account 
          </Typography>
       
      
        
       
          <div className="p-4">
          <p className={` ' text-center pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
            <b>Are you sure you want to permanently delete your account? This action cannot be undone.</b></p>

          <center className='mt-4'>
            <button className='cancel-btn mx-2' onClick={handleClose}>Cancel</button>
            <button className='logout-btn mx-2' onClick={handleDeleteAccount}>Delete</button>

          </center>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default Overview;
