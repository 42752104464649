import React, { useState, useEffect } from 'react';
import Avatar from "../../../Images/Maskgrouptest.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import verified from "../../../Images/verified.png";


function AppliedRequest(user) {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  // function AppliedRequest() {
  console.log(user, "usereeeee")
  return (
    <div className="col-md-12 mt-3">
      <div className={` ' inner-shadow theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`} style={{overflow:"hidden"}}>
        <h3 className={` '  overview-heading pt-3 text-center  theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
          Job Created By{" "}
          <span className="float-end">
          </span>
        </h3>
     

        <div className="d-flex mt-2 mb-2 p-3">
          <img src={user?.reason?.userId?.profileImage} alt="" className="side-bar-profile" />

          <div className="side-bar-name-nav pb-2 mx-3 pt-2">
          <h2 className={`d-flex theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
            {user?.reason?.userId?.name}
            {(user?.reason?.userId?.videoVerify === 1) && (
              <span className="mt-1 ms-2">
                <img src={verified} alt="verified" />
              </span>
            )}
          </h2>

            
            <p className={` '  theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
              {`${user?.reason?.userId?.about.substring(0, 50)}`}...
            </p>
            <h2></h2>
            {/* <p>{user.reason.userId.description}</p> */}
            {/* <h6 className="pt-1"><Link to="/job-created">View Details</Link></h6> */}
          </div>
        </div>
     
        <div  className={` '  refer_by_info-bg p-3  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color-filter' : 'light-theme'}`}>
       <div className={` '  row  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color-filter' : 'light-theme'}`}>
       <div className="col-md-4 refer_by_info">
            <h5>Referred By </h5>
            <p className="pt-2">Name </p>
            {/* <p>Message</p> */}
            <p>Status</p>
          </div>
          <div className="col-md-8 refer_by_info">
            <h5 style={{ visibility: "hidden" }}>Referred By</h5>
            <p className="pt-2 d-flex" style={{color:"#787878"}}>{user.reason.referBy.name}  {(user.reason.referBy.videoVerify === 1) && (
              <b className="mt-2 ms-2">
                <img src={verified} alt="verified" />
              </b>
            )}</p>
            {/* <p>{user.reason.referBy.about}</p> */}

            <span
  style={{
    backgroundColor:
      user.reason.status === 3 ? 'rgba(255, 0, 0, 0.2)' : // Red with 65% opacity
      user.reason.status === 2 ? 'rgba(241, 11, 128, 0.2)' : // Pinkish color with 65% opacity
      '#D9F1FF', // Other status with 65% opacity
    color: [3, 2, 1].includes(user.reason.status) ? 'red' : '#02A0E3', 
    padding: '4px 15px',
    borderRadius: '100px', // Optional: adds rounded corners for better styling
  }}
>
  {(() => {
    switch (user.reason.status) {
      case 0:
        return "Pending";
      case 1:
        return "In Progress";
      case 2:
        return "Accepted";
      case 3:
        return "Rejected";
      default:
        return "Unknown Status";
    }
  })()}
</span>



          </div>
       </div>

          {/* <div className="col-md-7 side-nav-info">
                <p className="pt-2 pb-2">+62 087867654670</p>
                <p>23 Main Street, Anytown, USA 12345</p>
            </div> */}
 
        </div>
      </div>
    </div>
  

  );
}

export default AppliedRequest;
