import React, { useState, useEffect } from 'react';
import { notifyError, notifySuccess } from '../../../toastNotifications';
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import FormGroup from "react-bootstrap/Form";
import {  updateHourlyPriceAPI } from '../../../services/api/updateparametersApi';
import { getMyProfile } from '../../../services/api/profileApi';
const Overview = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [hourlyPrice, setHourlyPrice] = useState("");

  // ✅ Fetch the stored hourly price when the component mounts
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchHourlyPrice = async () => {
      setIsLoading(true);
      try {
        const response = await getMyProfile();
        console.log(response);
        // Correct path to access hoursPrice from the API response
        const storedPrice = response.data.user.hoursPrice;
        // Convert to string to avoid type issues
        setHourlyPrice(storedPrice ? storedPrice.toString() : "");
      } catch (error) {
        console.error("Error fetching hourly price:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchHourlyPrice();
  }, []);
  



  // ✅ Save theme preference
  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  const updateHourlyPrice = async (values, { setSubmitting }) => {
    try {
      await updateHourlyPriceAPI(values.hourlyPrice);
      notifySuccess("Hourly price updated successfully!");
      setHourlyPrice(values.hourlyPrice); // ✅ Update state
      localStorage.setItem("hourlyPrice", values.hourlyPrice); // ✅ Store new value in localStorage
    } catch (error) {
      notifyError("Error updating price: " + error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="container">
      <div className="row px-3 row-top-margin p-5 pt-0">
        <div className={`inner-shadow theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
          <div className="pb-2 mx-3 pt-2 text-center mb-5 mt-4">
            <h3 className={`overview-heading-wallet theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
              Hourly Price
            </h3>
             
            {isLoading ? (
      <div>Loading...</div>
    ) :(<Formik
      initialValues={{ hourlyPrice: hourlyPrice || "" }} // ✅ Show stored value
      enableReinitialize={true} // ✅ Ensure form updates when state changes
      validationSchema={Yup.object({
        hourlyPrice: Yup.number()
          .required("Hourly price is required")
          .min(1, "Must be at least 1"),
      })}
      onSubmit={updateHourlyPrice}
    >
      {({ isSubmitting }) => (
        <Form className='text-start mt-4'>
          <FormGroup>
            <Field
              type="number"
              name="hourlyPrice"
              placeholder="Hourly price"
              className={`form-control form-input ${theme === "dark" ? "dark-theme-only-color" : "light-theme"}`}
            />
            <ErrorMessage name="hourlyPrice" component="div" className="text-danger" />
          </FormGroup>
          <center className='mt-4'>
            <button type="submit" className='logoutt-btn mx-2' disabled={isSubmitting}>
              {isSubmitting ? "Processing..." : hourlyPrice ? "Update" : "Add"}
            </button>
          </center>
        </Form>
      )}
    </Formik>)}
            {/* Formik Form Implementation */}
            

            <p className="mt-4 text-start">
              The hourly price in "Ask Human" is the fixed cost users pay for one-on-one conversations with responders, charged in full-hour increments.
            </p>

            <h4 className='text-start mt-3'>How It Works:</h4>
            <ul className='delete-account text-start pt-2'>
              <li>Set by Responders: Responders determine their hourly rate based on expertise and demand.</li>
              <li>Hourly Sessions: Users pay for a full hour upfront. To continue beyond an hour, users must pay for the next hour.</li>
              <li>Transparent Pricing: Hourly rates are shown before starting a session.</li>
            </ul>

            <p className='text-start pt-3'>Example:</p>
            <ul className='delete-account text-start pt-2'>
              <li>$60/hour → A session lasting up to 1 hour costs $60. To continue, another $60 is charged for the next hour.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
