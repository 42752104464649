import axiosInstance from '../axiosInstance';

export const login = async (credentials) => {
    try {
        console.log(credentials,"credentialscredentialscredentials")
        const response = await axiosInstance.post('/auth/login', credentials);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const verifyOtp = async (data) => {
    try {
        const response = await axiosInstance.post('/auth/verify',data);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const otpResendToPhoneVerification = async (data) => {
    try {
        const response = await axiosInstance.post('/auth/otpResend', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const socialLogin = async (credentials) => {
    try {
        const response = await axiosInstance.post('/auth/sociallogin', credentials);
        console.log("response.data.data.user.stripeCustomer", response.data.data.user.stripeCustomer)
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("mobileOtp", response.data.data.mobileOtp);
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
        localStorage.setItem(
            "stripeCustomer",
            JSON.stringify({ "id": response.data.data.user.stripeCustomerId })
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const logout = async () => {
    try {
        const response = await axiosInstance.post('/auth/logout');
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const deleteAccount = async () => {
    try {
        const response = await axiosInstance.delete('/auth/deleteAccount'); // Change POST to DELETE
        return response.data;
    } catch (error) {
        throw error;
    }
};
    

export const register = async (userData) => {
    try {
        console.log(userData, "userDatauserDatauserData")
        const response = await axiosInstance.post('/auth/signUp', userData);
        console.log(response, "responseresponseresponseresponse")
        return response.data;

    } catch (error) {
        throw error;
    }
};

export const phoneVerification = async (data) => {
    try {
        console.log(data, "userDatausdataerDatauserData")
        const response = await axiosInstance.post('/auth/phoneVerification', data);
        console.log(response, "responseresponseresponseresponse")
        return response.data;

    } catch (error) {
        throw error;
    }
};

export const changePhoneNumber = async (data) => {
    try {
        const response = await axiosInstance.put('/user/changePhoneNumber', data);
        return response.data;

    } catch (error) {
        throw error;
    }
};


export const verifyPhoneNumber = async (data) => {
    try {
        const response = await axiosInstance.put('/user/verifyPhoneNumber', data);
        return response.data;

    } catch (error) {
        throw error;
    }
};



// Example usage of the common axios function
export const signIn = async (userData) => {
    let data = { ...userData }
    try {
        const response = await axiosInstance.post('/auth/login', data);
        console.log("response.data.data.user.stripeCustomer", response.data.data.user.stripeCustomer)
        localStorage.setItem("token", response.data.data.user.token);
        localStorage.setItem("mobileOtp", response.data.data.mobileOtp);
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
        localStorage.setItem(
            "stripeCustomer",
            JSON.stringify({ "id": response.data.data.user.stripeCustomerId })
        );
        return response.data;
    } catch (error) {
        throw error
    }
};

// Additional authentication-related API functions can be added here


export const forgotPassword = async (data) => {
    try {
        const response = await axiosInstance.post('/auth/forgotPassword', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const forgetPasswordOtpVerify = async (data) => {
    try {
        const response = await axiosInstance.post('/auth/forgetPasswordOtpVerify', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const forgotSetNewPassword = async (data) => {
    try {
        const response = await axiosInstance.post('/auth/setNewPassword', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};
