import axiosInstance from '../axiosInstance'; 

export const HelpData = async (data) => {
    try {
        const response = await axiosInstance.get(`/user/content/help`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

