import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import logo from "../../Images/asknewlogo__1_-removebg-preview.png";
// import Form from 'react-bootstrap/Form';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { styled } from "@mui/material/styles";
import google from "../../Images/googleicon.png";
import apple from "../../Images/appleicon.png";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import Switch from "@mui/material/Switch"; // Import Switch from MUI
import { signIn, socialLogin,login } from "../../services/api/authApi"; // Import signIn function from your authApi service
import { notifySuccess, notifyError } from "../../toastNotifications"; // Import notification functions
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  socket,
  socketMessageContext,
} from "../../context/SocketMessageContext";
import { signInWithGooglePopup } from "../../firebase/config"; // Adjust the path based on your actual folder structure
import NavbarSignFlow from "../Login/NavbarSignFlow";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { TextField, InputAdornment, Button } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import phonenumbersvg from "../../Images/signupsvgs/phonenumber.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import countryPhoneData from './countryPhoneData';

function Index() {
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("91");
  const [isSigning, setIsSigning] = useState(false);
  const [phoneError, setPhoneError] = useState("");
;
  


  console.log(countryPhoneData,'We are country phone data');
  const handleSubmit = () => {
    alert(`Phone Number Submitted: ${phone}`);
  };
  const Container = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    width: "100%",
    maxWidth: "400px",
    margin: "auto",
  });

  const Label = styled("label")({
    fontSize: "14px",
    color: "#666",
    alignSelf: "flex-start",
  });

  const StyledButton = styled(Button)({
    background: "linear-gradient(90deg, #ff006a, #8400ff)",
    borderRadius: "25px",
    color: "#fff",
    padding: "12px",
    width: "100%",
    fontSize: "16px",
    textTransform: "none",
    "&:hover": {
      opacity: 0.9,
    },
  });
  // const handleGoogleLogin = async () => {
  //   try {
  //     const result = await auth.signInWithPopup(googleProvider);
  //     const user = result.user;
  //     console.log('Logged in user:', user);
  //     // Redirect or perform actions after successful login
  //   } catch (error) {
  //     console.error('Google login failed:', error.message);
  //   }
  // };


  const getPhoneLengthRequirement = (code) => {
    code=Number(code);
    // Find the country in the data where phone matches the countryCode
    const country = countryPhoneData.find(c => Number(c.phone) ===code);
    
    if (!country) return { min: 10, max: 10 }; // Default fallback
    
    if (Array.isArray(country.phoneLength)) {
      // If phoneLength is an array, return min and max values
      return { 
        min: Math.min(...country.phoneLength), 
        max: Math.max(...country.phoneLength) 
      };
    } else if (country.min !== undefined && country.max !== undefined) {
      // If min and max are defined directly
      return { min: country.min, max: country.max };
    } else if (country.phoneLength) {
      // If phoneLength is a single number
      return { min: country.phoneLength, max: country.phoneLength };
    }
    
    // Default fallback
    return { min: 10, max: 10 };
  };


  const validatePhoneNumber = () => {
    const requirement = getPhoneLengthRequirement(countryCode);
    
    if (phone.length < requirement.min) {
      setPhoneError(`Phone number must be at least ${requirement.min} digits for this country`);
      return false;
    }
    
    if (phone.length > requirement.max) {
      setPhoneError(`Phone number cannot exceed ${requirement.max} digits for this country`);
      return false;
    }
 // Check for repeated digits (e.g., 000000, 9999999)
 if (/^(\d)\1+$/.test(phone)) {
  setPhoneError("Phone number cannot contain all repeated digits");
  return false;
}

// Check for sequential numbers (ascending and descending)
const isSequential = (str) => {
  const sequence = "0123456789012";
  const reverseSequence = "9876543210987";
  return sequence.includes(str) || reverseSequence.includes(str);
};

if (isSequential(phone)) {
  setPhoneError("Phone number cannot be a sequential number");
  return false;
}

// Check for repeating patterns (like 121212 or 123123)
if (/^(?:(\d+)(?=\1))+$/.test(phone) || /^(\d{2,})\1+$/.test(phone)) {
  setPhoneError("Phone number cannot contain repeating patterns");
  return false;
}

// Check for alternating digits (like 121212...)
if (/^(\d)(\d)(?:\1\2)+$/.test(phone)) {
  setPhoneError("Phone number cannot contain alternating digits");
  return false;
}

    
    setPhoneError("");
    return true;
  };

  const signInWithPhone = async (values) => {
    if (!validatePhoneNumber()) {
      return; // Stop submission if validation fails
    }
    console.log(values,phone,"valuesvaluesvalues")
    let uniqueDeviceId = localStorage.getItem(`deviceId`)
    // if (!countryCode || countryCode.trim() == "") {
    //   notifyError("Please select country code");
    //   return;
    // } else if (!uniqueDeviceId) {
    //   window.location.reload()
    // } else {
      setIsSigning(true);
      console.log('I am country code without string converion',countryCode);
        
      console.log('I am the phone of before data ',phone);
      const data = {
        phone:phone,
        countryCode: `+${countryCode}`,
        deviceType: 'web',
        deviceId: uniqueDeviceId,
      };
      try {
        const response = await login(data);
        if (response?.statusCode == 200) {
          console.log(response,"responseresponse")
          notifySuccess(response?.message);
          localStorage.setItem('user', JSON.stringify(response?.data?.newUser || response?.data?.user) || null)
          localStorage.setItem('token', response?.data?.user?.token)
          localStorage.setItem('otp', response?.data?.user?.otp)
          localStorage.setItem('phone', response?.data?.user?.mobile)
          localStorage.setItem('countryCode', response?.data?.user?.countryCode)
          navigate('/otp-verification')
        } else {
          notifyError(response?.message)
        }
      } catch (error) {
        notifyError(error?.response?.data?.message || "Something went wrong")
      } finally {
        setIsSigning(false);
      }
    // }
  };
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithGooglePopup();
      const user = result.user;
      console.log("Logged in user:", user);
      if (user && user?.uid) {
        let data = {
          socialId: user?.uid,
          socialType: "google",
          email: user?.email,
          fcmToken: "web",
        };
        try {
          const response = await socialLogin(data);
          console.log("socialLogin RESPONSE here :", response);
          if (response.statusCode === 200) {
            socket.emit("addUser", response.data.user._id);
            notifySuccess("Logged in successfully!");
            if (response.data.user.profileComplete == 0) {
              // navigate('/create-profile')
              navigate("/finish-profile-setup");
            } else {
              navigate("/home"); // Redirect to the home page
            }
          }
        } catch (error) {
          console.log("socialLogin ERROR here :", error);
          notifyError(error?.response?.data?.message || `Something went wrong`);
        }
      } else {
        throw new Error("No user found");
      }
      // Handle successful login
    } catch (error) {
      console.error("Google Sign-In Error:", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    }
  };
  // const socket = useContext(socketContext)
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#E30B83" : "#E30B83",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.(?:com|in|net|org|edu|gov|co\.uk)$/;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, "Invalid email format")
      .required("Email is required!"),

    password: Yup.string()
      .trim()
      .test(
        "notEmptyAfterTrim",
        "Password is required!",
        (value) => value.trim() !== ""
      )
      .required("Password is required!"),
  });

  const [showPassword, setShowPassword] = useState(false);
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [loading, setLoading] = useState(false);
  const [isSafari, setIsSafari] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

 
  useEffect(() => {
    const userAgent = navigator.userAgent;
    setIsSafari(/^((?!chrome|android).)*safari/i.test(userAgent));
    // setIsChrome(/chrome/i.test(userAgent));
  }, []);

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <>
      {/* <NavbarSignFlow/> */}
      <div
        className={` ' container-fluid theme-switcher ${
          theme === "dark" ? "black-color" : "light-theme"
        }`}
      >
        <div className="row">
          <div className="col-md-7 login-left-section px-5">
            <div className="justify-content-center align-self-center">
              <h2>International ask Question with Ask Human!</h2>
              <p>Enter your phone number to sign in.</p>
            </div>
          </div>
          <div
            className={` ' col-md-5 login-right-section m-auto theme-switcher ${
              theme === "dark" ? "dark-theme-only-white" : "light-theme"
            }`}
          >
             <button className={` ' back-slide mt-3 ml-2 theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                  }`} onClick={() => navigate(-1)}>
             <FontAwesomeIcon icon={faArrowLeft} /> Back
              </button>
            {/* <p className='p-4 login-right-section-p'>
            Don’t have an account? <b><Link to="/sign-up" className={` '  sign-up-text text-dark theme-switcher ${theme === 'dark' ? 'dark-mode-pink-color' : 'light-theme'}`}>Sign up</Link></b>
          </p> */}
            <div className="ask-to-sign   pt-3">
              <h2 className="d-flex pt-5">Sign In to Ask Human!</h2>
              <img
                src={logo}
                alt="Ask Human Logo"
                className="m-auto pt-3 pb-2"
              />
              <p className="back-to-ask-human">Welcome back to Ask Human</p>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="w-100 mt-4">
                      <label
                        htmlFor="mobile"
                        className={`form-label theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                        }`}
                      >
                        Phone number
                      </label>
                      <div className="d-flex gap-2">
                        <PhoneInput
                          country={"in"}
                          enableSearch={true}
                          enableLongNumbers={true}
                          className={` theme-switcher ${
                            theme === "dark"
                              ? "dark-theme-county-code"
                              : "light-theme"
                          }`}
                          value={countryCode}
                          onChange={(countryCode) =>{
                            setCountryCode(countryCode);
                            setPhone('');
                            setPhoneError('');
                          
                          
                          }
                          }
                          inputStyle={{
                            background: theme === "dark" ? "black" : "unset",
                            borderRadius: "100px 0px 0px 100px",
                            width: "114px",
                            padding: "25px 6px 23px 60px",
                            border: !countryCode
                              ? "2px solid #ff4949"
                              : "1px solid #CACACA",
                            overflow: "hidden",
                          }}
                          inputProps={{
                            readOnly: true, // Prevent typing in the input field
                          }}
                          countryCodeEditable={false}
                          dropdownStyle={{
                            backgroundColor:
                              theme === "dark" ? "black" : "white",
                          }}
                        />
                        <div className="css-input-container w-100">
                          <img
                            src={phonenumbersvg}
                            alt=""
                            className="css-input-icon"
                          />
                          <Field
                            type="text"
                            id="mobile"
                            name="mobile"
                            placeholder="Enter Your Phone Number"
                            onChange={(e) => {
                              console.log(e.target.value,"mobilemobilemobile");
                              setPhone(e.target.value);
                            }}
                            onBlur={validatePhoneNumber}
                            className={` ps-5 css-form-input css-form-input-new-reduis  form-input theme-switcher ${
                              theme === "dark"
                                ? "dark-theme-only-color"
                                : "light-theme"
                            }`}

                            onKeyPress={(e) => {
                              if (!/^\d$/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            maxLength={getPhoneLengthRequirement(countryCode).max}
                          />
                        </div>
                      </div>
                      {phoneError && <div className="text-danger mt-2">{phoneError}</div>}
                      <ErrorMessage
                        name="mobile"
                        component="div"
                        className="text-danger"
                      />
                    </div>
            

                    {isSigning ? (
                      <button
                        type="submit"
                        className="sign-in-btn mt-4"
                        
                        disabled={true}
                      >
                        Signing in...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        onClick={signInWithPhone}
                        className="sign-in-btn mt-4"
                        disabled={!!phoneError}
                        style={{ cursor: phoneError ? 'not-allowed' : 'pointer' }}
                      >
                        Sign in
                      </button>
                    )}
                  </Form>
                )}
              </Formik>

              <p className="continue-with mt-4 ">Or continue with</p>
              <center className="button-center d-flex mt-4 mb-5">
                <button
                  className={
                    isSafari
                      ? `google-btn mx-2`
                      : `google-btn mx-2 w-100 d-flex justify-content-center`
                  }
                  onClick={signInWithGoogle}
                >
                  <img src={google} alt="Google" className="google-btn-icon" /> {"\u00A0"}Sign in with
                  Google
                </button>
                {isSafari ? (
                  <button className="apple-btn w-100 w-100 d-flex justify-content-center mx-2">
                    <img src={apple} alt="Apple" /> {"\u00A0"}Sign in with Apple
                  </button>
                ) : (
                  ""
                )}
              </center>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
