import axiosInstance from '../axiosInstance';

export const searchHastags = async (data) => {
    try {
        const response = await axiosInstance.get(`/user/hashtags/getHashtags/${data}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const requestVerificationHashtag = async (dataVal) => {
    try {
        const response = await axiosInstance.post(`/user/hashtags/requestVerification`, dataVal);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateHashtags = async (hourlyPrice, hashtags = []) => { 
    try {
        const parsedHashtags = hashtags.map(tag => parseInt(tag) || tag); // Convert numeric values, keep strings as is

        const response = await axiosInstance.put(`/user/updateparameters`, { 
            hoursPrice: hourlyPrice, // hourlyPrice ko number hi rehne do
            hashtags: parsedHashtags // Parsed hashtags bhejna
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};