import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import xmark from '../../Images/x-mark.png';
import money1 from '../../Images/money1.png';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import profile9 from '../../Images/profile-9.png';
import { logout } from "../../services/index";
import { notifyError, notifySuccess } from '../../toastNotifications';
import logou from '../../Images/logou.svg';

function CustomImage9() {
  return <img src={profile9} alt="Your Alt Text" className="me-2 image-inline" />;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,

};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
function BasicModal() {
  const [open, setOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const[question,setQuestion]=useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
    
    // Correct way to retrieve and parse the saved note
    const savedNoteString = localStorage.getItem('saved-note');
    if (savedNoteString) {
      try {
        const saveddata = JSON.parse(savedNoteString);
        console.log('Saved note data in edit question:', saveddata);
        setQuestion(saveddata.note || '');
      } catch (error) {
        console.error('Error parsing saved note:', error);
        setQuestion('');
      }
    }
  }, []);
  

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  const handleSave = () => {
    try {
      // Get the current saved note
      const savedNoteString = localStorage.getItem('saved-note');
      let savedNote = savedNoteString ? JSON.parse(savedNoteString) : {};
      
      // Update the note property
      savedNote.note = question;
      
      // Save back to localStorage
      localStorage.setItem('saved-note', JSON.stringify(savedNote));
      
      notifySuccess('Question updated successfully');
      handleClose();
    } catch (error) {
      console.error('Error saving note:', error);
      notifyError('Failed to save question');
    }
  };

  return (
    <div style={{ width: '100%' }}>
      {/* <Tab
        label="Logout"
        icon={<CustomImage9 />}
        {...a11yProps(8)}
        onClick={handleOpen}
        className={`tabs-tab theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
        style={{ width: '100%' }}
      /> */}
         <p className="link-modal mt-3 mb-2" onClick={handleOpen}>{question}</p>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={` 'inner-shadow p-0 border-0 border-rduis theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
          

<img 
  src={xmark} 
  alt="" 
  className="x-mark m-2" 
  onClick={handleClose} 
  style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }} 
/> {/* Add onClick handler here */}
        
          <Typography id="modal-modal-title" variant="h6" component="h2" className='are-you-sure-heading p-3'>
          Edit Question 
          </Typography>
      <div className="p-4">
        
      <textarea 
      className={` ' theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
  name=""     
  value={question}
  onChange={(e) => {
    
    const inputValue = e.target.value;
    // Filter out anything that's not a letter or space
    const filteredValue = inputValue.replace(/[^a-zA-Z\s']/g, '');
    // Update state with filtered value
    setQuestion(filteredValue);
  
  }}
  id="" 
  style={{ width: '100%', height: '100px', border: '1px solid #ccc', borderRadius: '15px', padding: '8px' }}>
</textarea>
          {/* <p className={` ' text-center pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
            <b>Are you sure, you want to logout your account?</b></p> */}

          <center className='mt-4'>
            <button className='cancel-btn mx-2' onClick={handleClose}>Cancel</button>
            <button className='logout-btn mx-2' onClick={handleSave} >Save</button>
          </center>
      </div>
        </Box>
      </Modal>
    </div>
  );
}

export default BasicModal;
