import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import BackIcon from "../../Images/croseicon.svg";
import { searchHastags } from "../../services";
import WhiteVerify from '../../Images/white-verify-2.png';

const HashtagsInput = ({ theme, values, setFieldValue, handleSubmitHash, initialHashtags = [], }) => {
  const [hashtags, setHashtags] = useState(() => {
    // Initialize hashtags from the "about" field if it has prefilled values
    // If initialHashtags are provided, use them directly
    if (initialHashtags.length) {
      return initialHashtags;
    }

    // Fallback: If values.about is provided as a string, split and initialize
    if (values.about) {
      return values.about.split(" ").map((title) => ({
        title,
        isVerified: 0, // Default to 0 if isVerified is not provided
      }));
    }

    return [];
  });
  const [inputValue, setInputValue] = useState("");
  const [searchedHastagsData, setSearchedHastagsData] = useState([]);

  const handleAddHashtag = (e) => {
    if (e.key === "Enter" && inputValue.trim()) {
      const newHashtag = inputValue.trim();
      
      if (!hashtags.some((hashtag) => hashtag?.title === newHashtag)) {
        const updatedHashtags = [...hashtags, { title: newHashtag }];
        setHashtags(updatedHashtags);
        console.log(updatedHashtags)
        setFieldValue("about", updatedHashtags.map(h => h.title).join(" "));
        
        // Ensure parent gets the updated hashtags
        if (handleSubmitHash) {
          handleSubmitHash(updatedHashtags);
        }
      }
      setInputValue("");
      e.preventDefault();
    }
  };
  
  const handleRemoveHashtag = (index) => {
    const updatedHashtags = hashtags.filter((_, i) => i !== index);
    setHashtags(updatedHashtags);
    setFieldValue("about", updatedHashtags.map(h => h.title).join(" "));
  
    // Ensure parent gets the updated hashtags
    if (handleSubmitHash) {
      handleSubmitHash(updatedHashtags);
    }
  };


  const fetchHastags = async (data) => {
    try {
      const response = await searchHastags(data);
      // console.log("searchHastags response ==>>", response);
      if (response.statusCode === 200) {

        let dataToSet = response?.data && response?.data?.length ? response?.data : [];
        setSearchedHastagsData(dataToSet)
        // notifySuccess(response.message);
      }
    } catch (error) {
      console.error("Error uploading photo:", error);
      // notifyError(error?.response?.data?.message || `Something went wrong`)
    }
  }

  useEffect(() => {
    console.log("Hashtags changed:", hashtags);
    if (handleSubmitHash) {
      console.log("Calling handleSubmitHash with:", hashtags);
      handleSubmitHash(hashtags);
    }
  }, [hashtags, handleSubmitHash]);



  useEffect(() => {
    if (inputValue) {
      fetchHastags(inputValue);
    }
  }, [inputValue])


  const formatUsedCount = (count) => {
    if (count < 100) return count.toString(); // Below 100, show exact count
    if (count < 1000) return Math.floor(count / 50) * 50 + "+"; // 100+, 150+, 200+...
    if (count < 10000) return (count / 1000).toFixed(1).replace(/\.0$/, "") + "K+"; // 1K+, 1.6K+...
    return "10K+"; // Max cap at 10K+
  };

  return (
    <div className="hashtag-container">
      <div
        className={`form-control  theme-switcher pb-2 ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
          }`}
        style={{
          height:
            !values?.about.trim() || values?.about.trim() === ""
              ? "125px"
              : "unset",
        }}
      >
        <Field
          name="about"
          placeholder="#hashtags"
          value={inputValue}
          onChange={(e) => {
            let text = e.target.value.trim()
            if (text) {
              setInputValue(text.toLowerCase())
            } else {
              setInputValue("");
            }
          }}
          onKeyDown={handleAddHashtag}
          className="add-hashtags"
        />
        <div  className={`hashtag-list theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"}`}>
          {hashtags.map((hashtag, index) => (
            <div key={index} className="hashtag-tag-new">
              <span className="hashtag-item-span">#{hashtag?.title}{hashtag?.isVerified == 1 ? <img src={WhiteVerify} alt="" /> : ''}</span>              <span
                className="remove-hashtag-new"
                onClick={() => handleRemoveHashtag(index)}
              >
                <img src={BackIcon} alt="Remove" className="icon-remove-color-set" />
              </span>
            </div>
          ))}
        </div>
      </div>
      <ErrorMessage name="about" component="div" className="text-danger" />
      <div className="hashtag-list mt-4">
        {searchedHastagsData &&
          searchedHastagsData
            .filter((searchedHashtag) =>
              !hashtags.some(
                (hashtag) => hashtag.title == searchedHashtag.title
              )
            ).map((hashtag, index) => (
              <div key={index} className="hashtag-tag" style={{ cursor: 'pointer' }}
                onClick={() => {
                  setHashtags([...hashtags, { id: hashtag?._id, title: hashtag?.title, isVerified: hashtag?.isVerified }]);
                  setInputValue("")
                  setSearchedHastagsData([]);
                }}>
                {/* <span>#{hashtag?.title}</span> */}
                <span className="hashtag-item-span">#{hashtag?.title}{hashtag?.isVerified == 1 ? <img src={WhiteVerify} alt="" /> : ''}</span>

                {hashtag?.usedCount ?
                  <span
                    className="remove-hashtag"
                    style={{
                      height: `31px`,
                      width: `31px`,
                      display: `flex`,  
                      justifyContent: `center`,
                      alignItems: `center`,
                      fontSize: '12px'
                    }}
                  // onClick={() => handleRemoveHashtag(index)}
                  >
                  
                    {formatUsedCount(hashtag?.usedCount || 0)}
                  </span> 
                  : ''}
              </div>
            ))}
      </div>
    </div>
  );
};

const App = ({ handleSubmitHashtags, initialHashtags = [] }) => {
  const aboutString = initialHashtags.map((hashtag) => hashtag.title).join(" ");

  const initialValues = {
    about: aboutString, // Convert to string for 'about'
  };


  // const onSubmit = (values) => {
  //   // handleSubmitHashtags(values);
  // };
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  
    useEffect(() => {
      const storedTheme = localStorage.getItem("theme");
      if (storedTheme) {
        setTheme(storedTheme);
      }
    }, []);
  
    useEffect(() => {
      localStorage.setItem("theme", theme);
    }, [theme]);
  

  return (
    <div className="app-container">
      <Formik initialValues={initialValues}
      // onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div   className={`form-group theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"}`}>
              <HashtagsInput
                // theme="light"
                values={values}
                setFieldValue={setFieldValue}
                handleSubmitHash={handleSubmitHashtags}
                initialHashtags={initialHashtags} // Pass initial hashtags here

              />
            </div>
           
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default App;
