import React, { useEffect, useState } from "react";
import ChangePasswordModal from './ChangePasswordModal';
import EditProfileModal from './EditProfileModal';
import ChangePhoneModal from './ChangePhoneModal';
import ChangeEmail from './ChangeEmail';
import { getMyProfile } from '../../services/api/profileApi';
import Loaders from '../Loaders/index'
import verified from "../../Images/verified.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Male from '../../Images/age.svg';
import Zodaic from '../../Images/agenew.svg';

import { faPen } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import CompleteProfileUser from "../../Images/CompleteProfileUser.svg"


function Overview() {
  const navigate = useNavigate();

  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);


  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getMyProfile();
        console.log(data.data.user, "datadatadata");
        setProfile(data.data.user);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchProfile();
  }, []);

  if (loading) {
    return <div>
      <Loaders />
    </div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="row px-1 row-top-margin">
      <div className="col-md-12">
        <div className={` inner-shadow p-4 side-nav-info-details  theme-switcher ${theme === 'dark' ? 'inner-shadow-dark' : 'light-theme'}`}>
          <h3 className={`text-center overview-heading theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`} >
          My Account{" "}
           
          </h3>
   
          <div className={`side-bar-profile-background p-3 dark-mode mt-3 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color-neww' : 'light-theme'}`}>
           <div className="" style={{width:"80px"}}>
           <img
              src={profile.profileImage ? profile.profileImage : CompleteProfileUser}
              alt="Profile"
              className="side-bar-profile object-fit-cover" 
              style={{width:"80px", height:"80px"}}
            />
           </div>
            <div className="side-bar-name-nav pb-2 mx-3 pt-2  ">
              <h2 className={` theme-switcher ${theme === 'dark' ? 'dark-theme-only-color-neww' : 'light-theme'}`}>
                <span className="d-flex align-items-center">
                  <span>{profile.name}</span>
                  {profile && profile?.videoVerify && profile?.videoVerify === 1
                    ? <img
                      src={verified}
                      alt="Verified"
                      className="mt-1 ms-2"
                    /> : ''}
                </span> </h2>
             
                <div  className={` flex items-center col-md-12 gap-1 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color-neww' : 'light-theme'}`}>
                    <button className="info-age d-flex gap-2"><img src={Male} alt="" /><span> {profile?.age}</span></button>
                    <button className="info-age d-flex gap-2"><img src={Zodaic} alt="" /><span> {profile?.zodiac}</span></button>
                  </div>
                  <div  className={` flex mt-2 items-center col-md-12 gap-1 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color-neww' : 'light-theme'}`}>
                  <span> {profile?.countryCode} {profile?.mobile}</span>
                  </div>
          
            </div>
          </div>
     



     <div className={`side-bar-profile-background p-3 dark-mode mt-3 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color-neww' : 'light-theme'}`} >
     <div className="">
     <span className="side-bar-profile-background-right">
              {/* <EditProfileModal data={profile} /> */}
               <div>
                    {/* <FontAwesomeIcon
                      icon={faPen}
                      onClick={() => navigate(`/complete-profile`)}
                      style={{ cursor: "pointer" }}
                    /> */}
                    <button className="edit-button" onClick={() => navigate(`/complete-profile`)}
                      style={{ cursor: "pointer" }}><span>Edit</span></button>
                    </div>
            </span>
     </div>
     <div className="row " >
            <div className="col-sm-6 side-nav-info">
              
              <p className={`  pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color-neww' : 'light-theme'}`}>Gender <span className={` side-nav-info-dot theme-switcher ${theme === 'dark' ? 'dark-theme-only-color-neww' : 'light-theme'}`}>:</span></p>
            </div>
            <div className="col-sm-6 side-nav-info-details side-nav-info-responsive">
             

             
              <p className={`  pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color-neww' : 'light-theme'}`}>{profile?.gender === 0 ? "Male" : profile?.gender === 1 ? "Female" : "Other" || '--'} </p>
            </div>
          </div>
     <div className="row " >
      
            <div className="col-sm-6 side-nav-info">
              
              <p className={`pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color-neww' : 'light-theme'}`}>Ethnicity <span className={` side-nav-info-dot theme-switcher ${theme === 'dark' ? 'dark-theme-only-color-neww' : 'light-theme'}`}>:</span></p>
            </div>
            <div className="col-sm-6 side-nav-info-details side-nav-info-responsive">
             

              <p className={` pt-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color-neww' : 'light-theme'}`}>{profile?.ethnicity || '--'} </p>
            </div>
          </div>
          
          {/* <div className="row ">
            <div className="col-sm-6 side-nav-info">
              <p className={` pt-2 pb-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Zodiac <span className={` side-nav-info-dot theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>:</span></p>
              <p className={`  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Age <span className={` side-nav-info-dot theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>:</span></p>
            </div>
            <div className="col-sm-6 side-nav-info-details side-nav-info-responsive">
              <p className={`pt-2 pb-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>{profile?.zodiac || "--"}</p>
              <p className={`theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>{profile?.age || "--"} </p>
            </div>
          </div> */}

          <div className="row ">
  <div className="col-sm-6 side-nav-info">
    <p className={` pt-2 pb-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color-neww' : 'light-theme'}`}>
      Language <span className={` side-nav-info-dot theme-switcher ${theme === 'dark' ? 'dark-theme-only-color-neww' : 'light-theme'}`}>:</span>
    </p>
  </div>
  <div className="col-sm-6 side-nav-info-details side-nav-info-responsive">
    <p className={`pt-2 pb-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color-neww' : 'light-theme'}`}>
      {profile?.languages?.length > 0
        ? profile.languages.map(lang => lang.name?.charAt(0).toUpperCase() + lang.name?.slice(1)).join(", ")
        : "--"}
    </p>
  </div>
</div>
     </div>

        
        
        </div>
      </div>

  
    </div>
  );
}

export default Overview;
