import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import ChangePasswordModal from "../ChangePasswordModal";
import EditProfileModal from "../EditProfileModal";
import ChangePhoneModal from "../ChangePhoneModal";
import { notifyError, notifySuccess } from '../../../toastNotifications';
// import image from '../../../Images/transformed-(1) 1.png';
import image from '../../../Images/33512733_8058698_1-removebg-preview 5.png';
import { getMyProfile, logout } from "../../../services/index";
import Hashtags from './HashtagComponent';


const Overview = () => {
const [open, setOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [formikData, setFormikData] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getMyProfile();
        console.log(data.data.user, "datadatadata");
        setFormikData(data.data.user);
      } catch (err) {
        // setError(err.message);
        // setLoading(false);
      }
    };
    fetchProfile();
    
    
  }, []);

  console.log(formikData,' I am formik data of outer index')

  const hanldeLogout = async () => {
    try {
      const response = await logout();
      console.log("logout res -->", response)
      if (response.statusCode === 200) {
        localStorage.clear();
        window.location.href = "/home";
        notifySuccess("logout successfully");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const { status, message, statusCode } = error.response.data;
        console.log("Status:", status);
        console.log("Message:", message);
        console.log("StatusCode:", statusCode);
        notifyError("logout failed: " + message);
      } else {
        notifyError("logout failed: " + error.message);
      }
    }
  };

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (

    <div className="container ">
      <div className="row px-3 row-top-margin p-5 pt-0">
      <div  className={` ' inner-shadow theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}>


        <div
          className=" pb-2 mx-3 pt-2 text-center mb-5 mt-4"
          id="wallet-section-two"
        >







          <h3  className={` ' overview-heading-wallet theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}>Hashtags</h3>
          {/* hastags fields */}
<Hashtags initialHashtags={formikData?.hashtags} hoursPrice={formikData?.hoursPrice}/>
          <center>
            <img src={image} alt="" className='mt-4'/>
          </center>
          <p className="mt-3 ">
          "Here are your hashtags – update them anytime!"
        </p>
     
        </div>
      </div>
    </div>
    </div>
  );
}

export default Overview;
