import React, { useState, useEffect } from 'react';
import { notifyError, notifySuccess } from '../../../toastNotifications';
import image from '../../../Images/file 1.png';
import { logout } from "../../../services/index";
import idImage from "../../../Images/logo-mini.png";
import VerifiedIcon from '../../../Images/verified 4.png';
import Male from '../../../Images/Male.svg';
import Age from '../../../Images/age.svg';
import Pisces from '../../../Images/Pisces.svg';
import FullAccess from '../../../Images/full access.png';
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import phonenumbersvg from "../../../Images/signupsvgs/phonenumber.svg";
import OTPDigit from './otpdigit'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { changePhoneNumber, forgetPasswordOtpVerify, forgotPassword, otpResendToPhoneVerification, phoneVerification, verifyOtp, verifyPhoneNumber } from '../../../services/api/authApi';
import { otpResendToPhoneChange } from '../../../services';
import * as Yup from "yup";

const Overview = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [isVerified, setIsVerified] = useState(false);
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("91");

  const { cred } = useParams();
  const navigate = useNavigate();
  const [filledOtp, setFilledOtp] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeLeft, setTimeLeft] = useState(119); // 119 seconds = 1 minute 59 seconds
  const [mobileNumber, setMobileNumber] = useState("");
  const validationSchema = Yup.object().shape({

    mobile: Yup.string().trim()
      .test('notEmptyAfterTrim', 'Phone number is required!', value => value.trim() !== '')
      .matches(/^[0-9]+$/, "Number must contain only digits")
      // .min(10, "Phone number must be exactly 10 digits")
      // .max(10, "Phone number must be exactly 10 digits") 
      // .matches(/^[0-9]+$/, "Number must contain only digits")
      .min(7, "Phone number must be at least 7 digits")
      .max(15, "Phone number must not exceed 15 digits")
      .required("Phone number is required!"),

  });

  const handleVerify = () => {
    setIsVerified(true);
  };

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  const handleVerificationPhoneChange = async () => {
    setIsSubmitting(true);
    // let data = {
    //   emailOrPhone: cred,
    //   otp: filledOtp.join(""),
    // }

    let data = {
      otp: filledOtp.join(""),
      phone: mobileNumber,
      countryCode: `+${countryCode}`,
      fcmToken: "web"
    }
    try {
      const response = await verifyOtp(data);
      console.log("verifyOtp response is here -->", response);
      if (response.statusCode === 200) {
        notifySuccess(`Phone number changed successfully`);
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 3000);
        // navigate("/profile");
        // window.location.href('/profile');
      }
    } catch (error) {
      console.error("Error in verifyPhoneNumber :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    } finally {
      setIsSubmitting(false);
    }
  }


  const handleFillOtp = (val) => {
    setFilledOtp(val);
  }


  //Submit Handlers //

  const handleForgotPassVerification = async () => {
    setIsSubmitting(true);
    let data = {
      emailOrPhone: cred,
      otp: filledOtp.join(""),
    }
    try {
      const response = await forgetPasswordOtpVerify(data);
      console.log("forgetPasswordOtpVerify response is here -->", response);
      if (response.statusCode === 200) {
        notifySuccess(response.message);
        localStorage.setItem("token", response.data.userDetails.token);
        navigate("/set-password");
      }
    } catch (error) {
      console.error("Error in forgetPasswordOtpVerify :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVerification = async () => {
    setIsSubmitting(true);
    let data = {
      otp: filledOtp.join(""),
      mobile: cred
    }
    try {
      const response = await phoneVerification(data);
      console.log("phoneVerification response is here -->", response);
      if (response.statusCode === 200) {
        // notifySuccess(response.message);
        // localStorage.setItem("token", response.data.userDetails.token);


        // navigate("/create-profile");
        window.location.href = "/home";
      }
    } catch (error) {
      console.error("Error in phoneVerification :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    } finally {
      setIsSubmitting(false);
    }
    // }
  };


  const handleResendPhoneOtp = async () => {
    if (timeLeft === 0) {
      let data = {
        // phone :phone , 
        // countryCode:countryCode
        phone: mobileNumber,
        countryCode: `+${countryCode}`,
      }
      try {
        const response = await otpResendToPhoneVerification(data);
        console.log("otpResendToPhoneVerification response is here -->", response);
        if (response.statusCode === 200) {
          notifySuccess(response.message);
          notifySuccess(response?.data?.otp);
          localStorage.setItem("mobileOtp", response.data.otp);
          setTimeLeft(119);
          setFilledOtp([]);
          // window.location.reload();
        }
      } catch (error) {
        console.error("Error in otpResendToPhoneVerification :", error);
        notifyError(error?.response?.data?.message || `Something went wrong`)
      }
    }
  }

  const handleResend = async () => {
    if (timeLeft === 0) {
      if (!cred || cred.trim() === "") {
        notifyError(`Email or phone number required`)
      } else {
        let data = {
          emailOrPhone: cred
        }
        try {
          const response = await forgotPassword(data);
          console.log("forgotPassword response is here -->", response);
          if (response.statusCode === 200) {
            notifySuccess(response.message);
            setTimeLeft(119)
          }
        } catch (error) {
          console.error("Error in forgotPassword :", error);
          notifyError(error?.response?.data?.message || `Something went wrong`)
        }
      }
    }
  }


  const handleResendPhoneChangeOtp = async () => {
    console.log(timeLeft)
    if (timeLeft === 0) {
      try {
        const response = await otpResendToPhoneChange();
        console.log("otpResendToPhoneChange response is here -->", response);
        if (response.statusCode === 200) {
          notifySuccess(response.message);
          localStorage.setItem("mobileOtp", response.data.otp);
          setTimeLeft(119);
        }
      } catch (error) {
        console.error("Error in otpResendToPhoneChange :", error);
        notifyError(error?.response?.data?.message || `Something went wrong`)
      }
    }
  }

  // Function to format seconds into "mm:ss" format
  const formatTime = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `0${min}:${sec < 10 ? '0' : ''}${sec}`;
  };



  const handleSubmit = async (values, { setSubmitting }) => {
    if (!countryCode || countryCode.trim() == "") {
      notifyError("Please select country code");
      return
    } else {
      setSubmitting(true);
      const { mobile } = values;
      const data = {
        phoneNO: mobile,
        countryCode: `+${countryCode}`,
      };
      try {
        const response = await changePhoneNumber(data);
        console.log("changePhoneNumber response is", response);

        if (response.statusCode === 200) {
          setMobileNumber(mobile);
          setTimeLeft(119)
          setIsVerified(true);
          notifySuccess(response?.data?.otp);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 3000);
          // navigate(`/otp-verification-change-change/${mobile}`);
        }
      } catch (error) {
        console.error("Error logging in:", error);
        notifyError(error?.response?.data?.message || `Something went wrong`)
      } finally {
        // handleClose();
        setSubmitting(false);
      }
    }
  }


  useEffect(() => {
    if (timeLeft === 0) return;
    const timer = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  // useEffect(() => {
  //   if (cred && window.location.pathname.includes("/otp-verification-phone") && localStorage.getItem('mobileOtp')) {
  //     notifySuccess(`Your verification otp is ${localStorage.getItem('mobileOtp')}`)
  //   }
  // }, []);

  return (
    <div className="container">
      <div className="row px-3 row-top-margin p-5 pt-0">
        <div className={`inner-shadow theme-switcher ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}>

          {/* ID Verification - Before Verification */}
          {!isVerified && (
            <div className="pb-2 mx-3 pt-2 text-center mb-5 mt-4" id="id-verification-one">
              {/* <h3 className={`overview-heading-wallet theme-switcher ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}>
                Phone Number
              </h3> */}
              <center>
                <img src={idImage} alt="" className='mt-2' style={{ maxWidth: "110px" }} />
              </center>
              <div className=" ask-to-sign  items-center mt-5">
                <h6>Add your New Phone number</h6>
                <p className={`text-sm text-center ${theme === "dark" ? "text-gray-300" : "text-dark-600"}`}>
                  Your new phone number must be different from previous used number.
                </p>
                <Formik
                  initialValues={{
                    mobile: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="w-100 text-start mt-4">
                        {/* <label
                        htmlFor="mobile"
                        className={`form-label theme-switcher ${
                          theme === "dark"
                            ? "dark-theme-only-white"
                            : "light-theme"
                        }`}
                      >
                        Phone number
                      </label> */}
                        <div className="d-flex gap-2">
                          <PhoneInput
                            country={"in"}

                            enableSearch={true}
                            enableLongNumbers={true}
                            value={countryCode}
                            onChange={(countryCode) =>
                              setCountryCode(countryCode)
                            }
                            inputStyle={{
                              background: theme === "dark" ? "black" : "unset",
                              borderRadius: "100px 0px 0px 100px",
                              width: "114px",
                              padding: "25px 6px 23px 60px",
                              border: !countryCode
                                ? "2px solid #ff4949"
                                : "1px solid #CACACA",
                              overflow: "hidden",
                            }}
                            inputProps={{
                              readOnly: true, // Prevent typing in the input field
                            }}
                            countryCodeEditable={false}
                            dropdownStyle={{
                              backgroundColor:
                                theme === "dark" ? "black" : "white",
                            }}
                          />
                          <div className="css-input-container w-100">
                            <img
                              src={phonenumbersvg}
                              alt=""
                              className="css-input-icon"
                            />
                            <Field
                              type="text"
                              id="mobile"
                              name="mobile"
                              placeholder="Enter Your Phone Number"
                              className={` ps-5 css-form-input css-form-input-new-reduis  form-input theme-switcher ${theme === "dark"
                                ? "dark-theme-only-color"
                                : "light-theme"
                                }`}
                              onKeyPress={(e) => {
                                if (!/^\d$/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          name="mobile"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      {/* <button
                        className='sign-in-btn mx-2 mt-4'
                        type="submit"
                        disabled={isSubmitting}
                      >Change Phone Number</button> */}

                      {isSubmitting ?
                        <button
                          type="submit"
                          className="sign-in-btn mt-4"
                          disabled={true}
                        >
                          Changing Phone Number...
                        </button> :
                        <button
                          type="submit"
                          className="sign-in-btn mt-4"
                          disabled={isSubmitting}
                        >
                          Change Phone Number
                        </button>}

                    </Form>
                  )}
                </Formik>

              </div>
            </div>
          )}

          {/* ID Verification - After Verification */}
          {isVerified && (
            <div className="pb-2 mx-3 pt-2 text-center mb-5 mt-4" id="id-verification-two">
              <h3 className={`overview-heading-wallet theme-switcher ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}>
                OTP Verification
              </h3>
              <center>
                <img src={idImage} alt="" className='mt-2' style={{ maxWidth: "110px" }} />
              </center>

              <div className="ask-to-sign mt-4 ">

                <p className={`back-to-ask-human pt-3 pb-3 theme-switcher ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}>Please enter the 4 digit code sent to  <br />
                  <b className={`text-dark  theme-switcher ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}> {
                    mobileNumber && countryCode
                    ? `+${countryCode}${" "}${mobileNumber}`
                    : `+91 9999999990`} </b> through SMS</p>
                <OTPDigit handleFillOtp={handleFillOtp} />
                {isSubmitting
                  ? <button className='sign-in-btn' disabled>Verifying...</button>
                  : <button className='sign-in-btn'
                    onClick={handleVerificationPhoneChange}
                    // onClick={
                    //   window.location.pathname.includes("/otp-verification-mail")
                    //     ? handleForgotPassVerification
                    //     : window.location.pathname.includes("/otp-verification-phone")
                    //       ? handleVerification
                    //       : window.location.pathname.includes("/otp-verification-change-change")
                    //         ? handleVerificationPhoneChange
                    //         : ''
                    // } 
                    disabled={isSubmitting}>Verify</button>}

                {timeLeft && timeLeft > 0
                  ? ''
                  : <> <p className={`'otp-span mt-2 pb-5 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Didn’t receive a code?  <span className='forgot-password' style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={handleResendPhoneOtp}
                  // onClick={
                    //   window.location.pathname.includes("/otp-verification-phone")
                    //     ? handleResendPhoneOtp
                    //     : window.location.pathname.includes("/otp-verification-mail")
                    //       ? handleResend
                    //       : window.location.pathname.includes("/otp-verification-change-change")
                    //         ? handleResendPhoneChangeOtp
                    //         : ''}
                            >
                    Resend OTP
                  </span></p>

                  </>
                }


                {timeLeft && timeLeft > 0
                  ? <p className={`pt-2 otp-span theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>{formatTime(timeLeft)}</p>
                  : ''}
              </div>

            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Overview;
