import React, { useEffect,useState } from 'react'
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { searchHastags } from "../../services";

const HashtagSelect = ({ onHashtagSelect }) => {
       const [inputValue, setInputValue] = useState('');
  const [selectedHashtags, setSelectedHashtags] = useState([]);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");


  const loadOptions=(inputValue,callback)=>{
         if(inputValue.length<2){
          callback([]);
         }

         searchHastags(inputValue).then(response=>{
              if(response.success){
                 const options=response.data.map((hashtag)=>({
                     value: hashtag._id,
            label: hashtag.title,
            isVerified: hashtag.isVerified,
            usedCount: hashtag.usedCount
                 }))    
                 callback(options);
                 console.log('I am options inside async ',options)
              }else{
                     callback([]);
              }
              
         }).catch((error)=>{
              console.log(error);
              callback([]);
         })
  }
   
  const handleInputChange=(newValue)=>{
     setInputValue(newValue);
     return newValue
  }

  const handleChange=(selectedOption)=>{
    if(selectedOption){
       setSelectedHashtags([...selectedHashtags,selectedOption]);
       onHashtagSelect(selectedOption.label);
       // setInputValue('');
    }
  }

  const customStyles = {
       option: (provided, state) => ({
         ...provided,
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'space-between',
         padding: '10px 15px',
       }),
     };

     const formatOptionLabel = ({ label, isVerified, usedCount }) => (
       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
         <div>#{label}</div>
         <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
           {isVerified === 1 && <span style={{ color: 'green' }}>✓</span>}
           {/* <span style={{ fontSize: '0.8em', color: 'gray' }}>Used: {usedCount}</span> */}
         </div>
       </div>
     );
     useEffect(() => {
      const storedTheme = localStorage.getItem("theme");
      if (storedTheme) {
        setTheme(storedTheme);
      }
    }, []);
   



     return (
       <AsyncSelect
       
         cacheOptions
         loadOptions={loadOptions}
         onInputChange={handleInputChange}
         onChange={handleChange}
         value={null} // Always reset after selection
         inputValue={inputValue}
         placeholder="Search hashtags..."
         formatOptionLabel={formatOptionLabel}
         styles={{
          color:"red",
          control: (baseStyles) => ({
            ...baseStyles,
            fontSize: '14px',
            borderRadius: '100px',
            outline:'none',
            minHeight: '40px',
        
              backgroundColor: theme === "dark" ? "#333" : "#fff", // Dark gray for dark mode, light pink for light mode
              color: theme === "dark" ? "#fff" : "#000", // White text in dark mode, black in light mode

            
          }),
          multiValue: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: theme === "dark" ? "#333" : "#fff", // Dark gray for dark mode, light pink for light mode
            color: theme === "dark" ? "#fff" : "#000" , // White text in dark mode, black in light mode
          }),
          
          multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            color: '#E50B83',
            
          }),
          multiValueRemove: (baseStyles) => ({
            ...baseStyles,
            color: '#E50B83',
            ':hover': {
              backgroundColor: '#E50B83',
              color: 'white',
            },
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: theme === "dark" ? "#000" : "#fff",
            color: theme === "dark" ? "#fff" : "#000",
          }),
          
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: theme === "dark" ? "#000" : "#fff",
            color: theme === "dark" ? "#fff" : "#000",
          }),
        }}
         noOptionsMessage={({ inputValue }) => 
           inputValue.length < 2 
             ? "Type at least 2 characters to search" 
             : "No hashtags found"
         }
        //  className={`w-100 ${localStorage.getItem('theme') === 'dark' ? 'dark-theme-only-color ' : 'light-theme'}`}
         className={`w-100  theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
         }`}
       />
     );
}

export default HashtagSelect
