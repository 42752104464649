import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import image from '../../Images/Group1686558039.png';
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import EnterOTP from './EnterOTP';
import xmark from "../../Images/x-mark.png";
import * as Yup from "yup";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { notifyError, notifySuccess } from '../../toastNotifications';
import { changePhoneNumber } from '../../services';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ code, mobile }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [countryCode, setCountryCode] = useState(code);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const validationSchema = Yup.object().shape({

    mobile: Yup.string().trim()
      .test('notEmptyAfterTrim', 'Phone number is required!', value => value.trim() !== '')
      .matches(/^[0-9]+$/, "Number must contain only digits")
      // .min(10, "Phone number must be exactly 10 digits")
      // .max(10, "Phone number must be exactly 10 digits")
      .required("Phone number is required!"),

  });



  const handleSubmit = async (values, { setSubmitting }) => {
    if (!countryCode || countryCode.trim() == "") {
      notifyError("Please select country code");
      return
    } else {
      setSubmitting(true);
      const { mobile } = values;
      const data = {
        phoneNO: mobile,
        countryCode: `+${countryCode}`,
      };
      try {
        const response = await changePhoneNumber(data);
        console.log("changePhoneNumber response is", response);

        if (response.statusCode === 200) {
          // notifySuccess(response.message);
          navigate(`/otp-verification-change-change/${mobile}`);
        }
      } catch (error) {
        console.error("Error logging in:", error);
        notifyError(error?.response?.data?.message || `Something went wrong`)
      } finally {
        handleClose();
        setSubmitting(false);
      }
    }
  }

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);



  return (
    <div>
      <button className="change-phone-number-btn mt-2" onClick={handleOpen}>Change</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={`overflow-visible inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
          <img src={xmark} alt="" className="x-mark" onClick={handleClose} />{" "}
          <h5 className='text-center'>Change Phone Number</h5>

          <Formik
            initialValues={{
              mobile: ''
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>

                <div className="mt-3 d-flex ">
                  <div className="w-100">
                    <label
                      htmlFor="mobile"
                      className={`px-5 py-2 text-center form-label theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
                      Your new phone number must be different from previous used number.
                    </label>
                    <div className="d-flex gap-2">
                      <PhoneInput
                        country={"in"}
                        enableSearch={true}
                        enableLongNumbers={true}
                        value={countryCode}
                        onChange={(countryCode) => setCountryCode(countryCode)}
                        inputStyle={{ borderRadius: `50px`, width: `135px`, padding: `12.5px 14px 12.5px 60px`, border: !countryCode ? `2px solid #ff4949` : `1px solid #CACACA`, }}
                      />
                      <Field
                        type="text"
                        id="mobile"
                        name="mobile"
                        placeholder="Enter your new phone number"
                        className={`w-75 form-input theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'
                          }`}
                        maxLength={16} // Maximum length of 16 digits
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allows only digits
                        }}
                        onBlur={(e) => {
                          if (e.target.value.length < 7) {
                            alert('Phone number must be at least 7 digits long.');
                            e.target.focus();
                          }
                        }}
                      />

                    </div>
                    <ErrorMessage name="mobile" component="div" className="text-danger" />
                  </div>
                </div>
                {isSubmitting ?
                  <button
                    type="submit"
                    className="sign-in-btn mt-4"
                    disabled={true}
                  >
                    Sending code...
                  </button> :
                  <button
                    type="submit"
                    className="sign-in-btn mt-4"
                    disabled={isSubmitting}
                  >
                    Send code
                  </button>}
              </Form>
            )}
          </Formik>



          {/* <Form.Label htmlFor="inputPhone" className={` ' form-label mt-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Enter your phone number</Form.Label>
          <div className="d-flex gap-2">
            <PhoneInput
              country={"in"}
              enableSearch={true}
              enableLongNumbers={true}
              value={countryCode}
              onChange={(countryCode) => setCountryCode(countryCode)}
              inputStyle={{ borderRadius: `50px`, width: `135px`, padding: `12.5px 14px 12.5px 60px`, border: !countryCode ? `2px solid #ff4949` : `1px solid #CACACA`, }}
            />
            <div className="password-input-container w-75">
              <Form.Control
                type="tel"
                id="inputPhone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                aria-describedby="passwordHelpBlockOld"
                className={`form-input theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                placeholder='Enter phone number'
              />
            </div>
          </div> */}

          {/* <EnterOTP /> */}

        </Box>
      </Modal>
    </div>
  );
}
