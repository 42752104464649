import axiosInstance from '../axiosInstance'; // Ensure this import path is correct

export const getAllUserList = async (data) => {
    try {
        const response = await axiosInstance.post(`/user/allUserList`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const updateProfile = async (data) => {
    try {
        const response = await axiosInstance.put(`/user/updateProfile`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const getUserDetails = async (id) => {
    try {
        const response = await axiosInstance.get(`/user/getUserDetails/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const otpResendToPhoneChange = async () => {
    try {
        const response = await axiosInstance.post('/user/otpResend');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const listEthnics= async ()=>{
    try {
        const response= await axiosInstance.get('/user/listEthnics');
        return response.data
        ;
    } catch (error) {
        throw error;
    }
}

export const listLanguages= async ()=>{
    try {
        const response= await axiosInstance.get('/user/listLanguages');
        return response.data;
    } catch (error) {
        throw error;
    }
}