import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import BackIcon from "../../../Images/croseicon.svg";
import { searchHastags } from "../../../services";
import WhiteVerify from '../../../Images/white-verify-2.png';
import {updateHashtags} from '../../../services'
import { notifyError, notifySuccess } from "../../../toastNotifications";
const HashtagsInput = ({ theme, values, setFieldValue, handleSubmitHash, initialHashtags = [],hoursPrice,handleChildData }) => {
  console.log('I have received inital hastags inner inner', initialHashtags)
  const [hashtags, setHashtags] = useState(initialHashtags || []);
  const [inputValue, setInputValue] = useState("");
  const [searchedHastagsData, setSearchedHastagsData] = useState([]);

  const handleAddHashtag = async (e) => {
    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault();
      
      const newHashtag = inputValue.trim();
      if (!hashtags.some((hashtag) => hashtag?.title === newHashtag)) {
        const updatedHashtags = [...hashtags, { title: newHashtag }];
        setHashtags(updatedHashtags);
        
        // Format hashtags for API
        const hastagsToSubmit = updatedHashtags.map(hashtag => ({ title: hashtag.title }));
        
        try {
          // Call API with updated hashtags
          await updateHashtags(hoursPrice, hastagsToSubmit);
          handleChildData(hastagsToSubmit);
          console.log("Hashtags updated successfully");
        } catch (error) {
          console.error("Error updating hashtags:", error);
        }
        
        setFieldValue("about", updatedHashtags.map(h => h.title).join(" "));
        
        // Notify parent component
        if (handleSubmitHash) {
          handleSubmitHash(updatedHashtags);
        }
      }
      setInputValue("");
    }
  };
  const handleRemoveHashtag = async (index) => {
    const updatedHashtags = hashtags.filter((_, i) => i !== index);
    setHashtags(updatedHashtags);
    try {
      // Call API with updated hashtags
      await updateHashtags(hoursPrice, updatedHashtags);
      handleChildData(updatedHashtags)
      console.log("Hashtags updated successfully");
    } catch (error) {
      console.error("Error updating hashtags:", error);
    }
    setFieldValue("about", updatedHashtags.map(h => h.title).join(" "));
    // Ensure parent gets the updated hashtags
    if (handleSubmitHash) {
      handleSubmitHash(updatedHashtags);
    }
  };

  

  const fetchHastags = async (data) => {
    try {
      const response = await searchHastags(data);
      // console.log("searchHastags response ==>>", response);
      if (response.statusCode === 200) {
        let dataToSet = response?.data && response?.data?.length ? response?.data : [];
        setSearchedHastagsData(dataToSet)
        // notifySuccess(response.message);
      }
    } catch (error) {
      console.error("Error uploading photo:", error);
      // notifyError(error?.response?.data?.message || `Something went wrong`)
    }
  }
  useEffect(() => {
    console.log("Hashtags changed:", hashtags);
    if (handleSubmitHash) {
      console.log("Calling handleSubmitHash with:", hashtags);
      handleSubmitHash(hashtags);
    }
  }, [hashtags, handleSubmitHash]);
  useEffect(() => {
    console.log("Updating hashtags from initialHashtags", initialHashtags);
    if (initialHashtags.length > 0) {
      setHashtags(initialHashtags);
    }
  }, [initialHashtags]);
  useEffect(() => {
    if (inputValue) {
      fetchHastags(inputValue);
    }
  }, [inputValue])
  const formatUsedCount = (count) => {
    if (count < 100) return count.toString(); // Below 100, show exact count
    if (count < 1000) return Math.floor(count / 50) * 50 + "+"; // 100+, 150+, 200+...
    if (count < 10000) return (count / 1000).toFixed(1).replace(/\.0$/, "") + "K+"; // 1K+, 1.6K+...
    return "10K+"; // Max cap at 10K+
  };
  const handleHashtagSelect = async (hashtag) => {
    // Add selected hashtag to the list
    const updatedHashtags = [...hashtags, { id: hashtag?._id, title: hashtag?.title, isVerified: hashtag?.isVerified }];
    
    // Update the local state
    setHashtags(updatedHashtags);
    setInputValue("");
    setSearchedHastagsData([]); // Clear the searched hashtags data
  
    // Format hashtags for API
    const hastagsToSubmit = updatedHashtags.map(hashtag => ({ title: hashtag.title }));
    
    try {
      // Call API to update hashtags
      await updateHashtags(hoursPrice, hastagsToSubmit);
      handleChildData(hastagsToSubmit); // Pass the updated hashtags to the parent component
      console.log("Hashtags updated successfully");
    } catch (error) {
      console.error("Error updating hashtags:", error);
    }
  
    // Update the 'about' field value in the Formik state
    setFieldValue("about", updatedHashtags.map(h => h.title).join(" "));
    
    // Notify parent component (if necessary)
    if (handleSubmitHash) {
      handleSubmitHash(updatedHashtags);
    }
  };
  
  
  return (
    <div className="hashtag-container">
      <div
        className={`form-control  theme-switcher pb-2 ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
          }`}
        style={{
          height:
            !values?.about.trim() || values?.about.trim() === ""
              ? "125px"
              : "unset",
        }}
      >
        <Field
          name="about"
          placeholder="#hashtags"
          value={inputValue}
          onChange={(e) => {
            let text = e.target.value.trim()
            if (text) {
              setInputValue(text.toLowerCase())
            } else {
              setInputValue("");
            }
          }}
          onKeyDown={handleAddHashtag}
          
          className="add-hashtags"
        />
        <div className="hashtag-list">
          {hashtags.map((hashtag, index) => (
            <div key={index} className="hashtag-tag-new" >
              <span className="hashtag-item-span">#{hashtag?.title}{hashtag?.isVerified == 1 ? <img src={WhiteVerify} alt="" /> : ''}</span>              <span
                className="remove-hashtag-new"
                onClick={() => handleRemoveHashtag(index)}
              >
                <img src={BackIcon} alt="Remove" className="icon-remove-color-set" />
              </span>
            </div>
          ))}
        </div>
      </div>
      <ErrorMessage name="about" component="div" className="text-danger" />
      <div className="hashtag-list mt-4">
        {searchedHastagsData &&
          searchedHastagsData
            .filter((searchedHashtag) =>
              !hashtags.some(
                (hashtag) => hashtag.title == searchedHashtag.title
              )
            ).map((hashtag, index) => (
              <div key={index} className="hashtag-tag" style={{ cursor: 'pointer' }}
              onClick={() => handleHashtagSelect(hashtag)}>
                {/* <span>#{hashtag?.title}</span> */}
                <span className="hashtag-item-span">#{hashtag?.title}{hashtag?.isVerified == 1 ? <img src={WhiteVerify} alt="" /> : ''}</span>
                {hashtag?.usedCount ?
                  <span
                    className="remove-hashtag"
                    style={{
                      height: `31px`,
                      width: `31px`,
                      display: `flex`,
                      justifyContent: `center`,
                      alignItems: `center`,
                      fontSize: '12px'
                    }}
                  // onClick={() => handleRemoveHashtag(index)}
                  >
                    {formatUsedCount(hashtag?.usedCount || 0)}
                  </span>
                  : ''}
              </div>
            ))}
      </div>
    </div>
  );
};
const App = ({ handleSubmitHashtags, initialHashtags = [],hoursPrice}) => {
  console.log('I have received intial hashtags new page hashtag',initialHashtags);
  const[tagstosubmit,setTagsTosubmit]=useState([]);
  const aboutString = initialHashtags.map((hashtag) => hashtag.title).join(" ");
  const initialValues = {
    about: aboutString, // Convert to string for 'about'
  };
  // const onSubmit = (values) => {
  //   // handleSubmitHashtags(values);
  // };
   const handleChildData= (data)=>{
      if(data){
        setTagsTosubmit(data);
      }
   }
  

  const onSubmit = async (values) => {
    const response= await updateHashtags(hoursPrice,tagstosubmit);
    try {
     
      if(response.statusCode===200){
        notifySuccess(response.message);
      }
    } catch (error) {
      notifyError(response.message);
    }
  };

  return (
    <div className="app-container">
      <Formik initialValues={initialValues}
      onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="form-group">
              <HashtagsInput
                theme="light"
                values={values}
                setFieldValue={setFieldValue}
                handleSubmitHash={handleSubmitHashtags}
                initialHashtags={initialHashtags}
                hoursPrice={hoursPrice} // Pass initial hashtags here
                handleChildData={handleChildData}
              />
            </div>
            <div>
            <button type="submit" className="hastags-save mt-3">
              Save
            </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default App;