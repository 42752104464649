import React, { useEffect, useState } from 'react';
import logo from "../Images/footer-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import 'aos/dist/aos.css';

import { faXTwitter } from '@fortawesome/free-brands-svg-icons';


function Footer() {
  // useEffect(() => {
  // window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
  // }, []); // Empty dependency array ensures this effect runs only once

  const navigate = useNavigate()

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);
  return (
    <footer>
      <div className={` ' footer-main pb-2 round-bold text-dark theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
        <div className="container-fluid">
          <div className="container pt-5">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <a href="#" className="footer_logo">
                      <img src={logo} alt="" />
                    </a>
                    <p className={` ' footer-lorem theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Ask questions from experts or earn rewards by answering others' queries on Ask Human dedicated to knowledge sharing and community engagement</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 about_ft">
                <h5 className="about_h5 ">Page</h5>
                <ul className="about_us_ul">
                  <li>
                    <Link
                      to="/home"
                      className={` ' link_decorations_white theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/profile"
                      className={` ' link_decorations_white theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                    >
                      profile
                    </Link>{" "}
                  </li>
                  <li>
                    <Link
                      to={"/contact"}
                      className={`link_decorations_white theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                    >
                      Contact us
                    </Link>{" "}
                  </li>
                  <li>
                    {/* onClick={() => navigate("/search-note")} className={`theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme' }`}
                  > */}
                    <Link
                      to={"/search-note"}
                      className={`link_decorations_white theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
                    >
                      Search notes
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 about_ft">
                <h5 className="about_h5 ">Company</h5>
                <ul className="about_us_ul">
                  <Link to={"/privacy-policy"} style={{textDecoration:"none"}}>
                  <li className={` '  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Privacy Policy</li></Link>
                  <Link to={"/terms&conditions"} style={{textDecoration:"none"}}>
                  <li className={` '  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Terms & conditions</li></Link>
                  <Link to={"/faq"} style={{textDecoration:"none"}}>
                  <li className={` '  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>FAQ</li></Link>
                  <Link to={"/how-it-works"} style={{textDecoration:"none"}}>
                  <li className={` '  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>How It Works</li></Link>
                  <Link to={"/blog"} style={{textDecoration:"none"}}>
                  <li className={` '  theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>Blog</li></Link>
                </ul>
              </div>
              <div className="col-lg-5 col-md-6 about_ft">
                <h5 className="about_h5 ">Subscribe</h5>
                <ul className="footer_nd_list">
                  <li>
                    <div className="reduis_angle" style={{ cursor: "pointer" }}>
                      <a href="https://www.facebook.com/askhumanapp/" target="_blank">
                        {" "}
                        <i className="fa-brands fa-facebook-f " />
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="reduis_angle" style={{ cursor: "pointer", paddingTop:"12px" }}>
                      <a href="https://x.com/askhumanapp" target="_blank">
                        {" "}
                       
                        <FontAwesomeIcon icon={faXTwitter} className='fa-brands faXTwitter' />
                        
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="reduis_angle" style={{ cursor: "pointer" }}>
                      <a href="https://www.instagram.com/askhumanapp/" target="_blank">
                        <i className="fa-brands fa-instagram" />
                      </a>
                    </div>
                  </li>
                  {/* <li>
                    <div className="reduis_angle" style={{ cursor: "pointer" }}>
                      <a href="https://www.pinterest.com/" target="_blank">
                        {" "}
                        <i className="fa-brands fa-pinterest" />
                      </a>
                    </div>
                  </li> */}
                  <li>
                    <div className="reduis_angle" style={{ cursor: "pointer" }}>
                      <a href="https://www.youtube.com/@AskHumanApp" target="_blank">
                        {" "}
                        <i className="fa-brands fa-youtube" />
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="reduis_angle" style={{ cursor: "pointer" }}>
                      <a href="https://www.reddit.com/user/Ask_human_ai/" target="_blank">
                      
                        <i class="fa-brands fa-reddit-alien"></i>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={`pb-2 container theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
      <hr className={` 'm-0 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`} />
      <div className="row">
              <div className="col-lg-12 text-center">
              <p className={` ' copy_right theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
          
          Copyright Ask Human. All right reserves
        </p>
              </div>
             
            </div>
            </div>
      </div>
      {/* <div className={`pb-4 container-fluid theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>

        <p className={` ' copy_right theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
          <hr className={` 'm-0 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`} />
          Copyright Ask Human. All right reserves
        </p>
      </div> */}
      
    </footer>
  );
}

export default Footer;
