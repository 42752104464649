import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import logo from "../../Images/logo-mini.png";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "../../Images/NotificationsIcon.png";
import profile from "../../Images/Ellipse28236.png";

const pages = [
  { label: "Home", href: "/home" },
  { label: "About Us", href: "/about-us" },
  { label: "Blog", href: "/blog" },
  { label: "FAQ", href: "/faq" },
  //   { label: 'Contact Us', path: '/' }
];

const settings = ["Profile", "Account", "Dashboard", "Logout", "Login"]; // Added 'Login' for logout path

function Navbar() {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  
   useEffect(() => {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme) {
          setTheme(storedTheme);
        }
      }, []);
    
      useEffect(() => {
        localStorage.setItem('theme', theme);
      }, [theme]);

  return (
    <div className="container-fluid  m-0 p-0 ">
      <AppBar 
  position="static" 
  className={`custom-header-compare theme-switcher ${theme === 'dark' ? 'dark-theme-only-color custom-header-compare-dark' : 'light-theme'}`}
>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component={NavLink}
              to="/home"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 800,
                letterSpacing: ".3rem",
                textDecoration: "none",
              }}
              style={{ color: "black" }}
            >
              <img src={logo} alt="" className="logo-nav p-2" />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.label} onClick={handleCloseNavMenu}  style={theme === 'dark' ? { color: 'white' } : {}}>
                    <a
                      href={page.href}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {page.label}
                    </a>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                textDecoration: "none",
              }}
              style={{ color: "black" }}
            >
              <img src={logo} alt="" className="logo-nav p-2" />
            </Typography>
            <Box
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
              className="ps-5"
            >
              {pages.map((page) => (
                <Button
                  sx={{
                    my: 2,
                    color: "black",
                    display: "block",
                    borderBottom:
                      location.pathname === page.path
                        ? "2px solid #A50C93"
                        : "none",
                  }}
                  className="nav-typography"
                >
                  <a
                    key={page.label}
                    component={NavLink}
                    href={page.href}
                    style={{
                      my: 2,
                      color: "black",
                      display: "block",
                      borderBottom:
                        location.pathname === page.path
                          ? "2px solid #A50C93"
                          : "none",
                      textDecoration: "none",
                    }}
                    className="nav-typography"
                    onClick={handleCloseNavMenu}
                  >
                    {page.label}
                  </a>
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {/* <Link to="/login"><button className='Login-btn'>Log In</button></Link> */}
              {/* <a href="#faq">sdfsd</a> */}
              {/* <p className='p-4 login-right-section-p'>
            Don’t have an account? <b><Link to="/sign-up" className={` '  sign-up-text text-dark theme-switcher ${theme === 'dark' ? 'dark-mode-pink-color' : 'light-theme'}`}>Sign up</Link></b>
          </p> */}

              <p className="p-4 login-right-section-p">
                Don’t have an account?{" "}
                <b>
                  {window.location.pathname.includes("/login") ? (
                    <Link to="/sign-up" className="sign-up-text">
                      {" "}
                      Sign up
                    </Link>
                  ) : (
                    <Link to="/login" className="sign-up-text">
                      {" "}
                      Sign in
                    </Link>
                  )}
                </b>
              </p>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default Navbar;
