import axiosInstance from '../axiosInstance'; 

export const updateHourlyPriceAPI = async (hourlyPrice) => {
    try {
        const response = await axiosInstance.put(`/user/updateparameters`, {
            hoursPrice: parseInt(hourlyPrice),
            hashtags: [] // Include if required
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

