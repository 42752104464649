import React, { useState, useEffect } from 'react';
import logo from '../../../Images/logo-mini.png';
import OTPDigit from './otpdigit'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { forgetPasswordOtpVerify, forgotPassword,verifyOtp, otpResendToPhoneVerification, phoneVerification, verifyPhoneNumber } from '../../../services/api/authApi';
import { notifySuccess, notifyError } from "../../../toastNotifications";
import { otpResendToPhoneChange } from '../../../services';
import NavbarSignFlow from '../../Login/NavbarSignFlow'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

function Index() {
  const { cred } = useParams();
  const navigate = useNavigate();
  const [filledOtp, setFilledOtp] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeLeft, setTimeLeft] = useState(119); // 119 seconds = 1 minute 59 seconds
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("91");
  //Submit Handlers //

  useEffect(()=>{
  let retrivedPhone= JSON.parse(localStorage.getItem('phone'));
  setPhone(retrivedPhone);   
  },[])

  const handleForgotPassVerification = async () => {
    setIsSubmitting(true);
    let data = {
      emailOrPhone: cred,
      otp: filledOtp.join(""),
    }
    try {
      const response = await forgetPasswordOtpVerify(data);
      console.log("forgetPasswordOtpVerify response is here -->", response);
      if (response.statusCode === 200) {
        notifySuccess(response.message);
        localStorage.setItem("token", response.data.userDetails.token);
        navigate("/set-password");
      }
    } catch (error) {
      console.error("Error in forgetPasswordOtpVerify :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVerification = async () => {
    setIsSubmitting(true);
    let data = {
      otp: filledOtp.join(""),
  
      phone: localStorage.getItem("phone"),
      countryCode: localStorage.getItem("countryCode"),
 
      fcmToken:"web"
    }
    console.log(data,"dataaaa")
    try {
      const response = await verifyOtp(data);
      console.log("phoneVerification response is here -->", response);
      if (response.statusCode === 200) {
        console.log(response,"response2222222222222222222222222222222222")
        localStorage.setItem("token", response.data.user.token);
        notifySuccess(response.message);
        if ( response.data.user.profileComplete ==0){
          window.location.href = "/sign-up"
        }else {
          window.location.href = "/home";
        }
   
        

        // navigate("/create-profile");

      }
    } catch (error) {
      console.error("Error in phoneVerification :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    } finally {
      setIsSubmitting(false);
    }
    // }
  };


  const handleVerificationPhoneChange = async () => {
    setIsSubmitting(true);
    let data = {
      emailOrPhone: cred,
      otp: filledOtp.join(""),
    }
    try {
      const response = await verifyPhoneNumber(data);
      console.log("verifyPhoneNumber response is here -->", response);
      if (response.statusCode === 200) {
        notifySuccess(response?.message);
        navigate("/profile");
        // window.location.href('/profile');
      }
    } catch (error) {
      console.error("Error in verifyPhoneNumber :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    } finally {
      setIsSubmitting(false);
    }
  }


  const handleFillOtp = (val) => {
    setFilledOtp(val);
  }



  // Resend Functions //

  const handleResendPhoneOtp = async () => {
    if (timeLeft === 0) {
      try {
        let data = {
          phone :phone , 
          countryCode:countryCode
        }
        const response = await otpResendToPhoneVerification(data);
        console.log("otpResendToPhoneVerification response is here -->", response);
        if (response.statusCode === 200) {
          notifySuccess(response.data.user.otp);
          localStorage.setItem("otp", response.data.user.otp);
          setTimeLeft(119)
          // window.location.reload();
        }
      } catch (error) {
        console.error("Error in otpResendToPhoneVerification :", error);
        notifyError(error?.response?.data?.message || `Something went wrong`)
      }
    }
  }

  const handleResend = async () => {
    if (timeLeft === 0) {
      if (!cred || cred.trim() === "") {
        notifyError(`Email or phone number required`)
      } else {
        let data = {
          emailOrPhone: cred
        }
        try {
          const response = await forgotPassword(data);
          console.log("forgotPassword response is here -->", response);
          if (response.statusCode === 200) {
            notifySuccess(response.message);
            setTimeLeft(119)
          }
        } catch (error) {
          console.error("Error in forgotPassword :", error);
          notifyError(error?.response?.data?.message || `Something went wrong`)
        }
      }
    }
  }


  const handleResendPhoneChangeOtp = async () => {
    console.log(timeLeft)
    if (timeLeft === 0) {
      try {
        const response = await otpResendToPhoneChange();
        console.log("otpResendToPhoneChange response is here -->", response);
        if (response.statusCode === 200) {
          notifySuccess(response.message);
          localStorage.setItem("mobileOtp", response.data.otp);
          setTimeLeft(119);
        }
      } catch (error) {
        console.error("Error in otpResendToPhoneChange :", error);
        notifyError(error?.response?.data?.message || `Something went wrong`)
      }
    }
  }

  // Function to format seconds into "mm:ss" format
  const formatTime = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `0${min}:${sec < 10 ? '0' : ''}${sec}`;
  };

  useEffect(() => {
    if (timeLeft === 0) return;
    const timer = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  // useEffect(() => {
  //   if (cred && window.location.pathname.includes("/otp-verification-phone") && localStorage.getItem('mobileOtp')) {
  //     notifySuccess(`Your verification otp is ${localStorage.getItem('mobileOtp')}`)
  //   }
  // }, []);

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);
  const checkUser = (val) => {
    if (localStorage.getItem(val)) {
      return JSON.parse(localStorage.getItem(val));
    } else {
      return null;
    }
  };
  useEffect(() => {
    let key = checkUser("user") || null;
    console.log(key,"keyyy")
    if (key && key.countrycode && key.phone) {
      setCountryCode(key.countryCode);
      setPhone(key.phone);
    }
    const isFirstLoad = localStorage.getItem("hasLoaded");
    console.log(isFirstLoad, "isFirstLoadisFirstLoadisFirstLoadisFirstLoadisFirstLoad")
    if (!isFirstLoad) {
      // Mark that the page has loaded
      localStorage.setItem("hasLoaded", "true");
      notifySuccess(localStorage.getItem("otp") || null);
    }
  }, []);
  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const { code } = values;
    const data = {
      otp: code.trim(),
    };}
  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <div>
      {/* <NavbarSignFlow /> */}
      <div className={` ' container-fluid theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`} >
        <div className="row">
          <div className="col-md-7 login-left-section px-5">
            <div className="justify-content-center align-self-center">
              <h2>International ask Question with Ask Human!</h2>
              <p>Enter OTP to verify its you.</p>
            </div>
          </div>
          <div className={` ' col-md-5 login-right-section m-auto theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
            {/* <p className='pt-4 px-4 login-right-section-p'>Don't have an account?
              <span>
                <b>
                  <Link to="/sign-up" className={` ' forgot-password text-dark theme-switcher ${theme === 'dark' ? 'dark-mode-pink-color' : 'light-theme'}`}> Signup</Link>
                </b>
              </span>
            </p> */}
             <button className="back-slide mt-3 ml-2" onClick={() => navigate(-1)}>
                         <FontAwesomeIcon icon={faArrowLeft} /> Back
                          </button>
           <div className="ask-to-sign mt-4">
  <h2 className="d-flex">OTP Verification</h2>
  
  <img src={logo} alt="Ask Human Logo" className="m-auto ask-to-sign-img pt-3 pb-2" />

  <p  className={` ' back-to-ask-human pt-3 pb-3 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
    Please enter the 4-digit code sent to <br />
    <b  className={` ' text-dark theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>+91 {phone}</b> through SMS
  </p>

  <OTPDigit handleFillOtp={handleFillOtp} />

  <button className="sign-in-btn" onClick={handleVerification} disabled={isSubmitting}>
    {isSubmitting ? "Verifying..." : "Verify"}
  </button>

  {timeLeft && timeLeft > 0 ? (
    <p className={`pt-2 otp-span theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
      {formatTime(timeLeft)}
    </p>
  ) : (
    <p className={`otp-span mt-2 pb-5 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
      Didn’t receive a code ? &nbsp;   
       <span 
        className="forgot-password" 
        style={{ cursor: 'pointer', textDecoration: 'underline' }} 
        onClick={handleResendPhoneOtp}
      >
         Resend OTP
      </span>
    </p>
  )}
</div>

          </div>
        </div>
      </div>
    </div >
  );
}

export default Index;
