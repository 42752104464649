import React, { useEffect, useRef } from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import xmark from "../../../Images/x-mark.png";
import money1 from "../../../Images/money1.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Identification from "../../../Images/bag-icon.png";
import VideoIdentification from '../../../Images/Video-Identification.png';
import UploadModal from './UploadModal';
import VideoRecorder from '../../../Identification/VideoRecorder';
import useUserMedia from '../../../Identification/useUserMedia';
import Hashtags2 from '../../../Images/Identification.png';
function CustomHashtags() {
  return (
    <img src={Hashtags2} alt="Your Alt Text" className="me-2 image-inline" />
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  // const videoRef = useRef();
  // const recorderRef = useRef(); // Initialize with null
  // const [capturing, setCapturing] = useState(false);
  // const [recordedChunks, setRecordedChunks] = useState([]);
  // const [uploading, setUploading] = useState(false);


  // const CAPTURE_OPTIONS = {
  //   video: true
  // };
  // const stream = useUserMedia(CAPTURE_OPTIONS);

  // useEffect(() => {
  //   if (videoRef.current && stream) {
  //     videoRef.current.srcObject = stream;
  //   }
  // }, [stream]);

  // const onCanPlay = () => {
  //   videoRef.current.play();
  // };

  // const onStartCapture = () => {
  //   setRecordedChunks([]);
  //   console.log("recorderRef.current", recorderRef.current);
  //   if (stream && !recorderRef.current) { // Check if stream is available and recorderRef is not yet initialized
  //     console.log("after if recorderRef.current", recorderRef.current);

  //     recorderRef.current = new MediaRecorder(stream);
  //     recorderRef.current.ondataavailable = (e) => {
  //       if (e.data.size > 0) {
  //         setRecordedChunks(prev => [...prev, e.data]);
  //       }
  //     };
  //     recorderRef.current.onerror = (e) => {
  //       console.error('Error while recording:', e.error);
  //     };
  //     recorderRef.current.start();
  //     setCapturing(true);
  //   }
  // };

  // const onStopCapture = () => {
  //   if (recorderRef.current && capturing) {
  //     recorderRef.current.stop();
  //     setCapturing(false);
  //     setUploading(true); // Start uploading
  //   }
  // };

  // useEffect(() => {
  //   console.log("working--");
  //   console.log("recordedChunks--", recordedChunks);

  //   if (recordedChunks.length > 0 && !capturing && uploading) {
  //     console.log("working--");
  //     const file = new File(recordedChunks, "user1Video.mp4", { type: 'video/mp4' });
  //     const reader = new FileReader();
  //     reader.onloadend = async () => {
  //       const dataURL = reader.result;
  //       const byteString = atob(dataURL.split(",")[1]);
  //       const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
  //       const ab = new ArrayBuffer(byteString.length);
  //       const ia = new Uint8Array(ab);
  //       for (let i = 0; i < byteString.length; i++) {
  //         ia[i] = byteString.charCodeAt(i);
  //       }
  //       const blob = new Blob([ab], { type: mimeString });
  //       const formData = new FormData();
  //       formData.append("video", blob, file.name);
  //       fetch(`${process.env.REACT_APP_WEB_BASE_URL}/user/videoIdentification/videoUpload`, {
  //         method: 'POST',
  //         headers: {
  //           "Authorization": `Bearer ${localStorage.getItem("token")}`
  //         },
  //         body: formData,
  //       })
  //         .then(response => response.json())
  //         .then(data => {
  //           console.log('Video uploaded successfully:', data);
  //           setData(data.videoUrl); // Assuming your API responds with the URL of the uploaded video
  //           setUploading(false); // Reset uploading state
  //         })
  //         .catch(error => {
  //           console.error('Error uploading video:', error);
  //           setUploading(false); // Reset uploading state on error
  //         });
  //     };
  //     // Read the file as a binary string
  //     reader.readAsDataURL(file);
  //   }
  // }, [recordedChunks, capturing, uploading, setData]);


  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);



  return (
    <div style={{opacity:"inherit"}}>
        <Tab
                  value={12}
                  onClick={handleOpen}
                  label="Identification"
                  icon={<CustomHashtags />}
                  {...a11yProps(12)}
                  className={`tabs-tab theme-switcher ${theme === 'dark' ? 'dark-theme-only-color-new' : 'light-theme'}`}
                  style={{ width: '100%', opacity:"1", color:"rgb(124 122 122)" }}
                />
      {/* <button className="search-btn w-100 mt-3" onClick={handleOpen}>Procced</button> */}
      {/* <h3  className="identification-modal">
        <span>
          <img
            src={Identification}
            alt=""
            className="user-identification-icon image-inline me-1"
          />
        </span>
        Identification
      </h3> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={` ' inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
          <img src={xmark} alt="" className="x-mark" onClick={handleClose} />{" "}
          {/* Add onClick handler here */}
          <center>{/* <img src={money1} alt="" /> */}</center>
          <h5 className="text-center">Video Identification </h5>


          <VideoRecorder handleClose={handleClose} />


        </Box>
      </Modal>
    </div>
  );
}