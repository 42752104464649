import React, { useRef, useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { notifyError } from "../../../toastNotifications";

const ForgotPasswordOTPVerify = ({ handleFillOtp }) => {
  const inputRefs = useRef(Array.from({ length: 4 }, () => React.createRef()));
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);

  const handleInputChange = (index, value) => {
    if (!/^\d*$/.test(value)) return; // Allow only numbers

    const newOtpInputs = [...otpInputs];
    newOtpInputs[index] = value;
    setOtpInputs(newOtpInputs);
    handleFillOtp(newOtpInputs);

    if (value && index < 3) {
      inputRefs.current[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      if (otpInputs[index]) {
        handleInputChange(index, ""); // Clear current field
      } else if (index > 0) {
        inputRefs.current[index - 1].current.focus();
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      inputRefs.current[index - 1].current.focus();
    } else if (e.key === "ArrowRight" && index < 3) {
      inputRefs.current[index + 1].current.focus();
    }
  };

  useEffect(() => {
    const handlePaste = (event) => {
      const pastedData = event.clipboardData.getData("text").trim();
      if (/^\d{4}$/.test(pastedData)) {
        const otpArray = pastedData.split("");
        setOtpInputs(otpArray);
        handleFillOtp(otpArray);
        otpArray.forEach((num, i) => {
          if (inputRefs.current[i]) inputRefs.current[i].current.value = num;
        });
      } else {
        notifyError("Invalid OTP pasted");
        setOtpInputs(["", "", "", ""]);
      }
    };

    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  
    useEffect(() => {
      const storedTheme = localStorage.getItem('theme');
      if (storedTheme) {
        setTheme(storedTheme);
      }
    }, []);
  
    useEffect(() => {
      localStorage.setItem('theme', theme);
    }, [theme]);

  return (
    <Box mb={3}>
      <Grid container spacing={1} justifyContent="center">
        {inputRefs.current.map((ref, index) => (
          <Grid item key={index}>
            <input
              type="text"
              className={`otp__digit theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
              inputMode="numeric"
              maxLength={1}
              ref={ref}
              value={otpInputs[index]}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              style={{
                height: "55px",
                width: "55px",
                textAlign: "center",
                fontSize: "20px",
                border: "1px solid #DBDBDB",
                borderRadius: "100px",
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ForgotPasswordOTPVerify;
