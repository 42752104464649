import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Slider, TextField, MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { searchHastags } from "../../services";
import AsyncSelect from 'react-select/async';
import { useParams } from "react-router-dom";

const CustomSlider = styled(Slider)({
  color: '#F10B80',
  height: 5,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    background: 'linear-gradient(to right, #820D9B, #F10B80)',
    border: '2px solid white',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: '1px 1px 20px #515151',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 25,
    height: 25,
    borderRadius: '50% 50% 50% 0',
    background: 'linear-gradient(to right, #820D9B, #F10B80)',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const Filters = ({hashtagsArray,setHashtagsArray,setSelectedItems,ethnicities,languages}) => {
  console.log('We are languages in the left filter',languages);
  console.log('We are ethnicities in left filter',ethnicities);
  const [ageRange, setAgeRange] = useState([18, 65]);
  const [hourlyPrice, setHourlyPrice] = useState([10,100]);
  const [ratings, setRatings] = useState(0);
  const[firstfourtags,setFirstFourTags]=useState([]);
  const { id, senderId } = useParams();
  const [selectedEthnicities, setSelectedEthnicities] = useState([]);
const [selectedLanguages, setSelectedLanguages] = useState([]);
const [checkedHashtags, setCheckedHashtags] = useState({});
const [checkedGenders, setCheckedGenders] = useState({});
const [checkedZodiacs, setCheckedZodiacs] = useState({});

useEffect(() => {
  const initialCheckedState = {};
  firstfourtags.forEach(tag => {
    initialCheckedState[tag.title] = hashtagsArray.includes(tag.title);
  });
  setCheckedHashtags(initialCheckedState);
}, [firstfourtags, hashtagsArray]);


  const loadEthnicOptions = (inputValue) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const filteredOptions = ethnicities.data?.ethnics.filter((ethnic) =>
          ethnic.ethnic.toLowerCase().includes(inputValue.toLowerCase())
        );
        resolve(filteredOptions?.map(ethnic => ({ label: ethnic.ethnic, value: ethnic.ethnic })));
      }, 1000);
    });
  };

  const handleEthnicityChange = (selectedOptions) => {
    setSelectedEthnicities(selectedOptions || []);
    const selectedEthnicities = selectedOptions?.map(option => option.value);
    setSelectedItems(prevItems => ({
      ...prevItems,
      ethnicity: selectedEthnicities
    }));
  };

  const handleAgeChange = (event, newValue) => {setAgeRange(newValue)
    
    setSelectedItems(previtems=>({
      ...previtems,
      minage:`${newValue[0]}`,
      maxage:`${newValue[1]}`

    }))
  };
  const handlePriceChange = (event, newValue) => {
    
    setHourlyPrice(newValue)
    setSelectedItems((prevItems)=>({
      ...prevItems,
      minPrice:newValue[0],
      maxPrice:newValue[1],
    }))


  };
  const handleRatingsChange = (event, newValue) =>{ 
    
    
    setRatings(newValue);
    setSelectedItems((prevItems)=>({
      ...prevItems,
      rating:newValue
    }))
  
  };
  const handleGenderChange=(e,newValue)=>{
    if(e.target.checked){
      if(newValue==='Male'){
        setSelectedItems((prevItems)=>({
          ...prevItems,
          gender:[...prevItems.gender,0]
        }))
      }else if(newValue==='Female'){
        setSelectedItems((prevItems)=>({
          ...prevItems,
          gender:[...prevItems.gender,1]
        }))
      }else{
        setSelectedItems((prevItems)=>({
          ...prevItems,
          gender:[...prevItems.gender,2]
        }))
      }
      
    }else {
      
      setSelectedItems((prevItems) => {
        const genderValue = newValue === 'Male' ? 0 : newValue === 'Female' ? 1 : 2;
        return {
          ...prevItems,
          gender: prevItems.gender.filter(item => item !== genderValue)
        };
      });
    }
  
  }

  const handleZodiacChange=(e,zodiacSign)=>{
    if(e.target.checked){
      setSelectedItems((prevItems)=>({
        ...prevItems,
        zodiac:[...(prevItems.zodiac||[]),zodiacSign]
      }))
    }else{
      setSelectedItems((prevItems)=>({
        ...prevItems,
        zodiac:[...(prevItems.zodiac||[]).filter((sign)=>sign!==zodiacSign)]
      }))
    }
  }

  useEffect(()=>{
  async function gettags(){
    const fetchtags=await searchHastags('a');
    console.log(fetchtags,'we are fetchedtags in left filter')
    setFirstFourTags(fetchtags.data?.slice(0,4));
    const slicedtags= fetchtags.data?.slice(0,4);
    console.log(slicedtags,'We are first four tags given in left filter')
   }

   gettags();

  },[])

  const loadLanguageOptions = (inputValue) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const filteredOptions = languages.data?.languages?.filter((language) =>
          language.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        resolve(filteredOptions?.map(language => ({ label: language.name, value: language._id })));
      }, 1000);
    });
  };

  const handleLanguageChange = (selectedOptions) => {
    setSelectedLanguages(selectedOptions || []);
    const selectedLanguageIds = selectedOptions?.map(option => option.value);
    setSelectedItems(prevItems => ({
      ...prevItems,
      languages: selectedLanguageIds
    }));
  };

  return (
    <div style={{ padding: 10 }}>
      <Typography variant="h6" style={{ fontWeight: 600 }}>
        Filters <span className="pt-1"
  style={{
    float: "right",
    background: "linear-gradient(to right, #E50B83, #9E0C94)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    cursor: "pointer",
    fontWeight: "500",
    fontSize: 15,
    textDecoration: "underline",
  }}
  onClick={()=>{
    setSelectedItems({
      search: "",
      userId: senderId || "",
      gender: [],
      ethnicity: [],
      zodiac: [],
      minage: "18",
      maxage: "72",
      hashtags:[],
      smoke: [],
      drink: [],
      workout: [],
      bodytype: [],
      ratings:0,
      hoursPrice:0,
      languages:[]
    })
    setHashtagsArray([]);
    setSelectedEthnicities([]);
    setSelectedLanguages([]);
    setCheckedHashtags({});
    setCheckedGenders({});
    setCheckedZodiacs({});
    setAgeRange([18, 72]);
    setHourlyPrice([10, 100]);
    setRatings(0);
  }}
>
  Reset all
</span>
      </Typography>

      <Typography variant="body1" style={{ fontWeight: 300, marginTop: 10 }}>Hashtags</Typography>
      {firstfourtags?.map((tag, index) => (
        <FormControlLabel key={index} control={<Checkbox sx={{ color: "#E50B83", '&.Mui-checked': { color: "#E50B83" } }} checked={checkedHashtags[tag.title] || false}/>} label={tag.title} onChange={(e)=>{
          setCheckedHashtags(prev => ({
            ...prev,
            [tag.title]: e.target.checked
          }));
          console.log(checkedHashtags,'we are checked hastags in left filter')
          if(e.target.checked){
            setHashtagsArray([...hashtagsArray,tag.title])
          }else{
            setHashtagsArray(hashtagsArray.filter(item=>item!=tag.title))
          }
          
        }}/>
      ))}
  <Typography 
  variant="body2"
  component="span"
  style={{
    display: "inline-block",
    background: "linear-gradient(to right, #E50B83, #9E0C94)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    cursor: "pointer",
    // Makes entire text bold
    fontSize: 15,
    textDecoration: "none", // Remove underline from the whole text
  }}
>
  {/* <span style={{ fontSize: 15 ,  }}>+</span> 
  <span style={{ textDecoration: "underline" }}> Add More</span> */}
</Typography>



      <Typography variant="body1" style={{ fontWeight: 300, marginTop: 10 }}>Age</Typography>
      <CustomSlider value={ageRange} onChange={handleAgeChange} valueLabelDisplay="auto" min={18} max={65} />

      <Typography variant="body1" style={{ fontWeight: 300, marginTop: 10 }}>Gender</Typography>
      {["Male", "Female", "TS"].map((gender, index) => (
        <FormControlLabel key={index} control={<Checkbox sx={{ color: "#E50B83", '&.Mui-checked': { color: "#E50B83" } }} checked={checkedGenders[gender] || false}/>} label={gender} onChange={(e)=>{
          
          handleGenderChange(e,gender)
          setCheckedGenders(prev => ({
            ...prev,
            [gender]: e.target.checked
          }));
        }}/>
      ))}

      <Typography variant="body1" style={{ fontWeight: 300, marginTop: 10 }}>Zodiac</Typography>
      {["Aries", "Gemini", "Leo", "Scorpio", "Capricorn", "Pisces", "Taurus", "Cancer", "Virgo", "Sagittarius", "Aquarius"].map((sign, index) => (
        <FormControlLabel key={index} control={<Checkbox sx={{ color: "#E50B83", '&.Mui-checked': { color: "#E50B83" } }} checked={checkedZodiacs[sign] || false} />} label={sign} onChange={(e)=>{
          handleZodiacChange(e,sign)
          setCheckedZodiacs(prev => ({
            ...prev,
            [sign]: e.target.checked
          }));
        }
        }
          
          
          />
      ))}

      <Typography variant="body1" style={{ fontWeight: 300, marginTop: 10 }}>Ethnicity</Typography>
      <AsyncSelect
        isMulti
        cacheOptions
        defaultOptions
        value={selectedEthnicities}
        loadOptions={loadEthnicOptions}
        onChange={handleEthnicityChange}
        placeholder="Search ethnicities..."
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            fontSize: '14px',
            minHeight: '40px',
          }),
          multiValue: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: '#f8e5f0',
          }),
          multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            color: '#E50B83',
          }),
          multiValueRemove: (baseStyles) => ({
            ...baseStyles,
            color: '#E50B83',
            ':hover': {
              backgroundColor: '#E50B83',
              color: 'white',
            },
          }),
          // Add these styles for the menu and options
          menu: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: '#fff',
            color: '#000',
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? '#f8e5f0' : '#fff',
            color: '#000',
          }),
        }}
      />

      <Typography variant="body1" style={{ fontWeight: 300, marginTop: 10 }}>Languages</Typography>
      <AsyncSelect
        isMulti
        cacheOptions
        defaultOptions
        loadOptions={loadLanguageOptions}
        onChange={handleLanguageChange}
        value={selectedLanguages}
        placeholder="Search languages..."
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            fontSize: '14px',
            minHeight: '40px',
          }),
          multiValue: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: '#f8e5f0',
          }),
          multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            color: '#E50B83',
          }),
          multiValueRemove: (baseStyles) => ({
            ...baseStyles,
            color: '#E50B83',
            ':hover': {
              backgroundColor: '#E50B83',
              color: 'white',
            },
          }),
          // Add these styles for the menu and options
          menu: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: '#fff',
            color: '#000',
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? '#f8e5f0' : '#fff',
            color: '#000',
          }),
        }}
      />

      <Typography variant="body1" style={{ fontWeight: 300, marginTop: 10 }}>Ratings</Typography>
      <CustomSlider value={ratings} onChange={handleRatingsChange} valueLabelDisplay="auto" min={0} max={5} />

      <Typography variant="body1" style={{ fontWeight: 300, marginTop: 10 }}>Hourly Price</Typography>
      <CustomSlider value={hourlyPrice} onChange={handlePriceChange} valueLabelDisplay="auto" min={10} max={100} />
    </div>
  );
};

export default Filters;
