import React, { useState, useEffect } from 'react';
import { notifyError, notifySuccess } from '../../../toastNotifications';
import image from '../../../Images/file 1.png';
import { logout } from "../../../services/index";
import idImage from "../../../Images/Frame 971.png"; 
import VerifiedIcon from '../../../Images/verified 4.png';
import Male from '../../../Images/Male.svg';
import Age from '../../../Images/age.svg';
import Pisces from '../../../Images/Pisces.svg';
import FullAccess from '../../../Images/full access.png';

const Overview = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [isVerified, setIsVerified] = useState(false);

  const handleVerify = () => {
    setIsVerified(true);
  };

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <div className="container">
      <div className="row px-3 row-top-margin p-5 pt-0">
        <div className={`inner-shadow theme-switcher ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}>
          
          {/* ID Verification - Before Verification */}
          {!isVerified && (
            <div className="pb-2 mx-3 pt-2 text-center mb-5 mt-4" id="id-verification-one">
              <h3 className={`overview-heading-wallet theme-switcher ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}>
                ID Verification
              </h3>
              <center>
                <img src={FullAccess} alt="" className='mt-2' style={{ maxWidth: "110px" }} />
              </center>
              <div className="flex flex-col items-center mt-2">
                <h6>ID Verification Required</h6>
                <p className={`text-sm text-center ${theme === "dark" ? "text-gray-300" : "text-dark-600"}`}>
                  Upload your ID to unlock full access.
                </p>
                <button className='logoutt-btn mx-2 mt-5' onClick={handleVerify}>Verify</button>
              </div>
            </div>
          )}

          {/* ID Verification - After Verification */}
          {isVerified && (
            <div className="pb-2 mx-3 pt-2 text-center mb-5 mt-4" id="id-verification-two">
              <h3 className={`overview-heading-wallet theme-switcher ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}>
                ID Verification
              </h3>
              <center>
                <img src={image} alt="" className='mt-2' style={{ maxWidth: "110px" }} />
              </center>
              <div className="flex flex-col items-center mt-2">
                <div className="flex items-center gap-2 font-semibold text-lg bg-gradient-to-r from-[#F10B80] to-[#8D0D99] text-transparent bg-clip-text">
                  <span>Verified</span>
                  <img src={VerifiedIcon} alt="" />
                </div>
                <p className={`text-sm mt-1 text-center ${theme === "dark" ? "text-gray-300" : "text-dark-600"}`}>
                  Thank you! <br /> You can now access all features securely.
                </p>
              </div>

              <center>
                <div className="mt-6 border rounded-md overflow-hidden shadow-sm" style={{ maxWidth: "500px" }}>
                  <img src={idImage} alt="ID" className="w-full" />
                </div>

                <div className={`row items-center mt-4 ${theme === "dark" ? "text-gray-300" : "text-gray-700"}`} style={{ maxWidth: "500px" }}>
                  <div className="flex items-center col-md-6 gap-1">
                    <img src={Male} alt="" /><span>Male</span>
                  </div>
                  <div className="flex items-center col-md-6 gap-1 justify-end">
                    <img src={Pisces} alt="" /> <span>Pisces</span> 
                  </div>
                  <div className="flex items-center col-md-6 mt-3 gap-1">
                    <img src={Age} alt="" /> <span>34</span>
                  </div>
                </div>
              </center>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Overview;
