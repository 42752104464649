import React, { useEffect, useState } from "react";
// import Image from "../../../Images/10782771_19197307.jpg";
import image from "../../Images/Requirements-for-Youtube-Channel-Monetization-1024x512-1-150x150_c.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { getAllBlogs } from "../../services";
import { notifyError } from "../../toastNotifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
function Blog() {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);
  const [blogsData, setBlogsData] = useState([]);


  const fetchAllBlogs = async () => {
    setBlogsData([]); // Clear the current blogsData state
    try {
      const response = await getAllBlogs();
      console.log("getAllBlogs response is here -->", response);
      if (response?.statusCode === 200) {
        let resBlogs = response?.data?.blogs || [];
  
        // Sort blogs by createdAt (latest first)
        resBlogs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  
        // Take only the 3 most recent blogs
        const latestBlogs = resBlogs.slice(0, 3);
  
        setBlogsData(
          latestBlogs.map((blog) => ({
            ...blog,
            readMore: false, // Initialize readMore state for each blog
          }))
        );
      }
    } catch (error) {
      console.error("Error in fetchAllBlogs :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`, {
        autoClose: 1000,
      });
    }
  };

  const toggleReadMore = (index) => {
    setBlogsData((prev) =>
      prev.map((blog, i) =>
        i === index ? { ...blog, readMore: !blog.readMore } : blog
      )
    );
  };

  useEffect(() => {
    fetchAllBlogs();
  }, []);

  return (
    <div>
      {/* <Helmet>
        <title>
          Blog | AskHuman AI: Insights on AI, Customer Support & Innovation{" "}
        </title>
        <meta
          name="description"
          content="Stay updated with the latest trends in AI and customer support. Explore insights, tips, and innovations from AskHuman AI on our blog."
        />
      </Helmet> */}
      <h2  className={` '  why-choose-h5 pt-5 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme' }`}>Read Our Blog</h2>
      <div className="container pb-5">
        <div className="row">
          {blogsData && blogsData.length > 0
            ? blogsData.map((blog, index) => {
                let _id = blog?._id || null;
                let title = blog?.title || "Not Found";
                let blog_id = blog?.blog_id || null;
                let slug = blog?.slug || null;
                let content = blog?.content || "<p>Not Found</p>";
                let createdAt = blog?.createdAt || null;
                let blogImg = blog?.imageUrls?.[0] || null;

                return (
                  <>
             

                    <div className="col-lg-4 col-md-6 mt-4 log ">
                      <Link
                        to={`/blog-details/${blog_id}/${slug}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div
                          className={` '  blog-card p-4 theme-switcher blog-height ${
                            theme === "dark"
                              ? "dark-theme-only-color"
                              : "light-theme"
                          }`}
                        >
                          <img
                            src={blogImg || image}
                            alt=""
                            style={{
                              height: "200px",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                          <p
                            className={` '  pt-2 theme-switcher ${
                              theme === "dark"
                                ? "dark-theme-only-white"
                                : "light-theme"
                            }`}
                          >
                            Blog
                          </p>

                          <h5
                            className={` ' pt-1 theme-switcher ${
                              theme === "dark"
                                ? "dark-theme-only-white"
                                : "light-theme"
                            }`}
                          >
                            {title.substring(0, 50)}
                            {title.length > 50 && "..."}
                          </h5>

                          <button
                            className={` ' theme-switcher ${
                              theme === "dark"
                                ? "dark-theme-only-white"
                                : "light-theme"
                            }`}
                          >
                            Read more{" "}
                            <FontAwesomeIcon
                              icon={faArrowRight}
                              className="blog-card-icon"
                            />{" "}
                          </button>
                        </div>
                      </Link>
                    </div>
                
                  </>
                );
              })
            : ""}
        </div>
      </div>
      <div className="container">
      <center>
      <Link
                        to={`/blog`}
                        style={{ textDecoration: "none" }}
                      >
                           <button className="logout-btn">View All</button>
                        </Link>
   
      </center>
      </div>
    
    </div>
  );
}

export default Blog;
