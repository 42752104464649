import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { notifyError, notifySuccess } from '../../../toastNotifications';
import { logout } from "../../../services";
import { HelpData } from "../../../services";
import Loaders from '../../Loaders/index';
import image from '../../../Images/image 6344585.png';

const Overview = () => {
  const [loading, setLoading] = useState(true);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [helpData, setHelpData] = useState([]);

  // Fetch Help Data
  useEffect(() => {
    const fetchHelpData = async () => {
      try {
        console.log("Fetching help data...");
        const response = await HelpData();
        console.log("API Response:", response);

        if (response.statusCode === 200) {
          setHelpData(response.data.content); // Adjusted to match API structure
        } else {
          notifyError("Failed to fetch help data");
        }
      } catch (error) {
        console.error("Error fetching help data:", error);
        notifyError("Failed to fetch help data");
      } finally {
        setLoading(false);
      }
    };

    fetchHelpData();
  }, []);

  // Logout Function
  const handleLogout = async () => {
    try {
      const response = await logout();
      if (response.statusCode === 200) {
        localStorage.clear();
        window.location.href = "/home";
        notifySuccess("Logout successful");
      }
    } catch (error) {
      notifyError("Logout failed: " + (error.response?.data?.message || error.message));
    }
  };

  if (loading) {
    return <Loaders />;
  }

  return (
    <div className="container">
      <div className="row px-3 row-top-margin p-5 pt-0">
        <div className={`inner-shadow theme-switcher ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}>
          <div className="pb-2 mx-3 pt-2 text-center mb-5 mt-4" id="wallet-section-two">
            <h3 className={`overview-heading-wallet theme-switcher ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}>
              Help
            </h3>
            <center>
              <img src={image} alt="Help Icon" className="mt-4" style={{ maxWidth: "110px" }} />
            </center>

            <div className="mt-5">
              {helpData.length > 0 ? (
                helpData.map((item) => (
                  <Accordion
                    key={item._id}
                    className={`accordion-setup ${theme === 'dark' ? 'dark-mode' : ''}`}
                    sx={{
                      backgroundColor: theme === 'dark' ? "#333" : "#ECECEC",
                      color: theme === 'dark' ? "#fff" : "#2F2F2F",
                      marginBottom: "8px",
                      borderRadius: "10px",
                      boxShadow: "none",
                      border: "none",
                      margin: "15px 0px"
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ margin: "12px 0px" ,fill: theme === 'dark' ? "#fff" : "#2F2F2F"}} />} style={{ border: "none", fontFamily: "Poppins" }}>
                      <Typography sx={{ fontWeight: "600" }} style={{ color: theme === 'dark' ? "#fff" : "#2F2F2F", border: "none" }}>
                        {item.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="text-start" style={{ fontStyle: "italic" }}>
                      <Typography style={{ color: theme === 'dark' ? "#ddd" : "#2F2F2F" }}>
                        {item.description.split('\n').map((line, index) => (
                          <p key={index}>{line}</p>
                        ))}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <p>No help content available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
